import { Http404Component } from './http404/http404/http404.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { PanelGuard } from './panel/guards/panel.guard';
import { LandingPagesComponent } from './landing-pages/landing-pages.component';


const routes: Routes = [
  {
    path: 'panel',
    loadChildren: './panel/panel.module#PanelModule',
    canActivate: [PanelGuard]
  },
  {
    path: '',
    loadChildren: './portal/portal.module#PortalModule'
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'landing_page/:url',
    component: LandingPagesComponent
  },
  {
    path: '**',
    component: Http404Component,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
