import { environment } from './../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { EcommerceService } from './../../services/ecommerce.service';
import { Component, OnInit } from '@angular/core';
import { PersistenceService, StorageType } from 'angular-persistence';
import { ToastrService } from 'ngx-toastr';
import { CatalogService } from '../../services/catalog.service';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {

    productId: number;
    productObject: any;
    apiURL: string = environment.apiUrl;
    qty: number = 1;
    cart: any;
    message: string;
    type: string;
    selectedImage: string;
    isDistributor: boolean;
    urlFacebook: string;
    twitterText: string;


    constructor(private ecommerceService: EcommerceService, private route: ActivatedRoute, private router: Router, private persistenceService: PersistenceService, private toastr: ToastrService, private catalogService: CatalogService, public meta: Meta, public title: Title) { }

    ngOnInit() {
        if(localStorage.getItem('distributor') == 'false' || !localStorage.getItem('distributor')){
            this.isDistributor = false;
        }else{
            this.isDistributor = true;
        }

        this.route.paramMap.subscribe( params => {
            this.type = params.get("type");
            this.productId = parseInt(params.get("id"));
            this.getProduct(this.type, this.productId);
        });

        if(this.persistenceService.get('cart', StorageType.SESSION) === undefined){
            this.cart = {"products_list": []};
        }else{
            this.cart = this.persistenceService.get('cart', StorageType.SESSION);
        }
    }

    onChangeQty(val: string){
        this.qty = parseInt(val);
        if(val > this.productObject['product'].max_purchase){
            this.qty = this.productObject['product'].max_purchase;
        }else if(parseInt(val) < 1){
            this.qty = 1;
        }
    }

    getProduct(type, id){
        if(type === 'product'){
            this.ecommerceService.getProductDetail(id).subscribe(res => {
                this.productObject = res;
                this.selectedImage = res['product']['image'];
                this.updateHead(res['product']);
            }, () =>{
                this.router.navigateByUrl('/products/all/1');
            });
        }else if(type === 'catalog'){
            this.catalogService.getCatalogProductDetail(id).subscribe(res => {
                this.productObject = res;
                this.selectedImage = res['image'];
                this.updateHead(res);
            });
        }
        
    }

    getProductCatalog(id){
        this.ecommerceService.getProductCatalogDetail(id).subscribe(res => {
            this.productObject = res;
            this.selectedImage = res['image'];
        }, () =>{
            this.router.navigateByUrl('/products/catalog/1');
        });
    }

    addProductCart(product: number, qty: number){
        var obj = this.cart['products_list'].find(o => o.id === this.productObject['product'].id);
        var previous = this.persistenceService.get('totalCart', StorageType.SESSION);

        if(obj === undefined){
            this.cart['products_list'].push({'id': product, "quantity": qty});
            this.persistenceService.set('cart', this.cart, {type: StorageType.SESSION});
            if(this.isDistributor){
                if(this.productObject['product']['discount'].length == 0){
                    this.ecommerceService.updateTotalCart(+previous+((+this.productObject['product'].distributor_price)*qty));
                }else{
                    this.ecommerceService.updateTotalCart(+previous+((this.productObject['product'].distributor_price-(this.productObject['product'].distributor_price*(this.productObject['product']['discount'][0]['discount_rate']/100)))*qty));
                }
            }else{
                if(this.productObject['product']['discount'].length == 0){
                    this.ecommerceService.updateTotalCart(+previous+((+this.productObject['product'].customer_price)*qty));
                }else{
                    this.ecommerceService.updateTotalCart(+previous+((this.productObject['product'].customer_price-(this.productObject['product'].customer_price*(this.productObject['product']['discount'][0]['discount_rate']/100)))*qty));
                }
            }
            this.toastr.success("Se agregó el producto " + this.productObject.product.name + " al carrito");

        }else{
            if(obj['quantity'] == this.productObject['product'].max_purchase){
                this.message = `Ya tienes el máximo de unidades por cliente en el carrito`;
            }else if((obj['quantity'] + qty) > this.productObject['product'].max_purchase){
                this.message = `La cantidad máxima es de ${this.productObject['product'].max_purchase} unidades por cliente. Solo puedes agregar ${this.productObject['product'].max_purchase - obj['quantity']} más`;
            }else{
                this.cart['products_list'].find((o, i) => {
                    if(o.id === product){
                        this.cart['products_list'][i] = {id: product, quantity: o.quantity + qty};
                        this.persistenceService.set('cart', this.cart, {type: StorageType.SESSION});    
                        if(this.isDistributor){
                            if(this.productObject['product']['discount'].length == 0){
                                this.ecommerceService.updateTotalCart(+previous+((+this.productObject['product'].distributor_price)*qty));
                            }else{
                                this.ecommerceService.updateTotalCart(+previous+((this.productObject['product'].distributor_price-(this.productObject['product'].distributor_price*(this.productObject['product']['discount'][0]['discount_rate']/100)))*qty));
                            }
                        }else{
                            if(this.productObject['product']['discount'].length == 0){
                                this.ecommerceService.updateTotalCart(+previous+((+this.productObject['product'].customer_price)*qty));
                            }else{
                                this.ecommerceService.updateTotalCart(+previous+((this.productObject['product'].customer_price-(this.productObject['product'].customer_price*(this.productObject['product']['discount'][0]['discount_rate']/100)))*qty));
                            }
                        }
                        this.toastr.success("Se agregó el producto " + this.productObject.product.name + " al carrito");
                        return true;
                    }
                });
            }
        }
    }

    updateSelectedImage(url: string){
        this.selectedImage = url;
    }

    updateHead(product){
        let urlDeatail = `${environment.serverUrl}/${this.type}/detail/${this.productId}/`;
        // this.title.setTitle(product.name);
        // this.meta.updateTag({name: 'description', content: product.description});
        // this.meta.updateTag({name: 'keywords', content: `Fleximatic, ${product.category.name}`});
        // this.meta.updateTag({property: 'og:title', content: product.name});
        // this.meta.updateTag({property: 'og:description', content: product.description});
        // this.meta.updateTag({property: 'og:image', content: `${this.apiURL}${product.image}`});
        // this.meta.updateTag({property: 'og:url', content: urlDeatail});
        // this.meta.updateTag({property: 'og:type', content: 'website'});
        // this.meta.updateTag({property: 'twitter:card', content: 'summary_large_image'});
        this.urlFacebook = encodeURIComponent(urlDeatail);
        this.twitterText = encodeURIComponent(product.name);
    }
}
