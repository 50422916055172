import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,  Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../../auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../models/user';
import { environment } from '../../../../environments/environment';

declare var $: any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profile_form = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('',[Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$"),]),
    avatar: new FormControl(''),
    is_active: new FormControl('true', Validators.required)
  });

  id: string;
  profile_obj: User;
  user_role: any = {};
  apiUrl: string = environment.apiUrl;

  constructor(private authService: AuthService, private router: Router, private toastr: ToastrService) { }

  ngOnInit() {
    this.profile_obj = new User;
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.authService.user_profile().subscribe( res => {
      this.id = res['id'];
      delete res['id'];
      delete res['user_type'];
      this.user_role = res['user_role'];
      delete res['user_role'];
      this.profile_obj = res;
      this.profile_form.setValue(this.profile_obj);
      this.profile_form.get('avatar').setValue('');
      $.unblockUI();
    });
  }

  onFileChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      return;
    }

    reader.onload = () => {
      this.profile_form.get('avatar').setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  save_profile(){
    const formData = new FormData();
    formData.append("first_name", this.profile_form.get('first_name').value);
    formData.append("last_name", this.profile_form.get('last_name').value);
    formData.append("email", this.profile_form.get('email').value);
    formData.append("user_type", "admin");
    formData.append("user_role", this.user_role['id']);
    formData.append("phone", this.profile_form.get('phone').value);
    formData.append("avatar", this.profile_form.get('avatar').value);
    formData.append("is_active", this.profile_form.get('is_active').value);

    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });

    this.authService.edit_user(this.id,formData).subscribe( res => {
      $.unblockUI();
      this.router.navigateByUrl('/panel/dashboard');
      this.toastr.success("Perfil modificado correctamente.");
    }, error => {
      let errors = error['error'];
      for (let e in errors) {
        this.toastr.error(errors[e],"Error!");
      }
      $.unblockUI();
    });

    
  }

}
