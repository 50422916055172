import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { ProductCategoriesService } from '../../services/product-categories.service';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-product-categories-form',
    templateUrl: './product-categories-form.component.html',
    styleUrls: ['./product-categories-form.component.css']
})
export class ProductCategoriesFormComponent implements OnInit {

    product_category_form = new FormGroup({
        name: new FormControl('', [Validators.required]),
        description: new FormControl(''),
        is_active: new FormControl('true', Validators.required),
        featured: new FormControl('false', Validators.required),
        image: new FormControl(''),
        image_hover: new FormControl('')
    });

    form_type: string;
    id: string;
    category_obj: any;
    apiUrl: string =  environment.apiUrl;

    constructor(private productCategory: ProductCategoriesService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        if (this.id == 'add') {
            this.form_type = 'Agregar';
            this.product_category_form.get('image').setValidators(Validators.required);
            this.product_category_form.get('image_hover').setValidators(Validators.required);
        } else {
            this.form_type = 'Editar';
            this.productCategory.product_category_detail(this.id).subscribe(res => {
                this.category_obj = res;
                delete res['id'];
                this.product_category_form.setValue(res);
                this.product_category_form.get('image').setValue('');
                this.product_category_form.get('image_hover').setValue('');
            }, error => {
                this.router.navigateByUrl('/panel/product_categories');
                this.toastr.error(error['error'].detail, "Error!");
            });
        }
    }

    save_product_category() {
        const formData = new FormData();
        formData.append("name", this.product_category_form.get('name').value);
        formData.append("description", this.product_category_form.get('description').value);
        formData.append("featured", this.product_category_form.get('featured').value);
        formData.append("image", this.product_category_form.get('image').value);
        formData.append("image_hover", this.product_category_form.get('image_hover').value);
        formData.append("is_active", this.product_category_form.get('is_active').value);

        if (this.form_type == 'Agregar') {

            this.productCategory.add_product_category(formData).subscribe(res => {
                this.router.navigateByUrl('/panel/product_categories');
                this.toastr.success("Categoría de producto creada correctamente.");
            }, error => {
                for (var key in error.error) {
                    this.toastr.error(error.error[key][0], "Error!");
                }
            });

        } else if (this.form_type == 'Editar') {
            this.productCategory.edit_product_category(this.id, formData).subscribe(res => {
                this.router.navigateByUrl('/panel/product_categories');
                this.toastr.success("Categoría de producto modificada correctamente.");
            }, error => {
                for (var key in error.error) {
                    this.toastr.error(error.error[key][0], "Error!");
                }
            });
        }
    }

    onImageChange(e, is_hover) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        var pattern = /image-*/;
        var reader = new FileReader();
        if (!file.type.match(pattern)) {
          this.toastr.warning("Formato inválido.");
          if(!is_hover){
            this.product_category_form.get('image').setValue('');
          }else{
            this.product_category_form.get('image_hover').setValue('');
          }
          return;
        }
    
        reader.onload = () => {
            if(!is_hover){
                this.product_category_form.get('image').setValue(reader.result as string);
            }else{
                this.product_category_form.get('image_hover').setValue(reader.result as string);
            }
        }
        reader.readAsDataURL(file);
      }


}
