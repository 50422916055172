export class Purchase {
    id: number;
    client: any;
    description: string;
    created_at: Date;
    amount: number;
    tax: number;
    total: number;
    shipping_method: any;
    shipping_cost: number;
    tracking_number: string;
    postal_code: number;
    state: string;
    city: string;
    street_number: string;
    colony: string;
    purchase_status: string;
    sale_products: any;
}