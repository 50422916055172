import { ProductsComponent } from './../../containers/products/products.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { EcommerceService } from './../../services/ecommerce.service';
import { CatalogService } from '../../services/catalog.service';
import { AuthService } from '../../../auth/services/auth.service';
import { environment } from '../../../../environments/environment';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { PersistenceService, StorageType } from 'angular-persistence';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

declare var $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {



    @Input() user: any;
    apiUrl: string = environment.apiUrl;

    searchForm = new FormGroup({
        search: new FormControl(''),
    });
    page: number;
    totalCart: string;
    subscription: any;
    type: string;
    showSuggests: boolean = false;
    productSuggests: any = [];
    catalogSuggests: any = [];

    dynamicbutton_id: number;
    dynamicbutton_List: any;
    dtTrigger = new Subject();


    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};


    constructor(private ecommerceService: EcommerceService, private catalogService: CatalogService, private auth: AuthService, private router: Router, private route: ActivatedRoute, private persistanceService: PersistenceService) {
        this.subscription = this.ecommerceService.getTotalCart().subscribe(subtotal => {
            this.totalCart = Number(subtotal).toFixed(2);
            this.persistanceService.set('totalCart', this.totalCart, { type: StorageType.SESSION });
        })
    }

    ngOnInit() {
        if (this.persistanceService.get('totalCart', StorageType.SESSION) === undefined) {
            this.totalCart = Number(0).toFixed(2);
            this.persistanceService.set('totalCart', 0, { type: StorageType.SESSION });
        } else {
            this.totalCart = Number(this.persistanceService.get('totalCart', StorageType.SESSION)).toFixed(2);
        }
        this.executeJS();

        this.getnamedynamic();

        this.searchForm.get('search').valueChanges.debounceTime(700).distinctUntilChanged()
            .subscribe((query) => {
                if (query === '') {
                    this.productSuggests = [];
                    this.catalogSuggests = [];
                    this.showSuggests = false;
                    return;
                }
                this.ecommerceService.getProductsByFilterPaginated(query, 1).subscribe(res => {
                    this.productSuggests = res;
                    this.showSuggests = true;
                });

                this.catalogService.getCategoryCatalogProductSearchByPage(query, 1).subscribe(res => {
                    this.catalogSuggests = res;
                    this.showSuggests = true;
                });
            });

    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    openSuggests() {
        if (this.catalogSuggests.length > 0 || this.productSuggests > 0) {
            this.showSuggests = true;
        } else {
            this.showSuggests = false;
        }
    }

    closeSuggests() {
        setTimeout(() => {
            this.showSuggests = false;
        },
            200);
    }

    executeJS() {
        $('.submenu .nav-link').click(function () {
            $(this).parent('.submenu').toggleClass('activo');
        });
        $('.submenu ul').click(function () {
            $(this).parent('.submenu').removeClass('activo');
        });
        $(document).ready(function () {
            $(window).bind('scroll', function () {
                var navHeight = $('.submenu, .m-menu').outerHeight();
                if ($(window).scrollTop() > navHeight) {
                    $('.submenu, .m-menu').addClass('shrink');
                }
                else {
                    $('.submenu, .m-menu').removeClass('shrink');
                }
            });
        });
    }


    filterProducts() {
        var search = this.searchForm.get('search').value;
        if (search === '') {
            return;
        }

        this.ecommerceService.getProductsByFilter(search).subscribe(res => {
            this.ecommerceService.objectCount = res['products'];
            this.ecommerceService.pagination = res['pages']
        });

        this.ecommerceService.getProductsByFilterPaginated(search, 1).subscribe(res => {
            this.ecommerceService.productsFilterList = res;

            this.ecommerceService.updateFilterMessage(search);
            this.searchForm.get('search').setValue('');
        });
        this.router.navigateByUrl(`/product_search/${search}/1/1`);
    }

    logout() {
        $.blockUI({
            message: '<i class="icon-spinner4 spinner"></i>',
            overlayCSS: {
                backgroundColor: '#1b2024',
                opacity: 0.8,
                zIndex: 1200,
                cursor: 'wait'
            },
            css: {
                border: 0,
                color: '#fff',
                padding: 0,
                zIndex: 1201,
                backgroundColor: 'transparent'
            }
        });
        this.ecommerceService.updateTotalCart(0);
        this.persistanceService.set('cart', { "products_list": [] }, { type: StorageType.SESSION });
        this.auth.logout().subscribe(res => {
            this.router.navigateByUrl('/');
            $.unblockUI();
        }, error => {
            console.log(error);
        });
    }

    isAuth() {
        if (this.auth.isAuth()) {
            if (localStorage.getItem('admin')) {
                return false;
            }
            return true;
        }
    }


    downloadCatalog_esp() {
        this.ecommerceService.downloadCatalog(0).subscribe(res => {
            window.open(res['pdf_url'], "_blank");
        })
    }

    downloadCatalog_eng(){
        this.ecommerceService.downloadCatalog(1).subscribe(res => {
            window.open(res['pdf_url'], "_blank");
        })
    }


    // BOTON DINAMICO 
    getnamedynamic() {
        this.ecommerceService.botton_list().subscribe(res => {
            this.dynamicbutton_List = res;
            this.dtTrigger.next();
        })
    }


}
