import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { AuthRoutingModule } from './auth-routing.module';
import { TokenInterceptor } from './token.interceptor';
import { AuthComponent } from './auth.component';
import { PanelLoginComponent } from './containers/panel-login/panel-login.component';
import { AuthService } from './services/auth.service';
import { PanelAccountActivationComponent } from './containers/panel-account-activation/panel-account-activation.component';
import { RecoveryPasswordComponent } from './containers/recovery-password/recovery-password.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { PortalLoginComponent } from './containers/portal-login/portal-login.component';
import { RegisterClientComponent } from './containers/register-client/register-client.component';



@NgModule({
  declarations: [
    AuthComponent,
    PanelLoginComponent,
    PanelAccountActivationComponent,
    RecoveryPasswordComponent,
    ResetPasswordComponent,
    PortalLoginComponent,
    RegisterClientComponent
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
  ]
})
export class AuthModule { }
