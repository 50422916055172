import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from '../../../../environments/environment';
declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input() user: any;
  apiUrl: string = environment.apiUrl;
  response: boolean = false;
  permissions: any = [];

  constructor(private auth: AuthService) { }

  get_permissions_list(){
    this.auth.user_permissions_list().subscribe( res => {
      this.permissions = res;
      this.response = true;
    });
  }

  ngOnInit() {
    this.get_permissions_list();
  }

  checkMenu(menu: string): boolean{
    if (!this.response){
      return true
    }
    return this.permissions.some( p => {
      return (p['menu'].title === menu)
    });
  }

  checkSubMenu(menu: string, submenu: string): boolean{
    return this.permissions.some( p => {
      return (p['menu'].title === menu && p['submenu'].title === submenu )
    });
  }

}
