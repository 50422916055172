import { ApplicantsService } from './../../../panel/services/applicants.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

declare var $: any;

@Component({
    selector: 'app-distributor',
    templateUrl: './distributor.component.html',
    styleUrls: ['./distributor.component.css']
})
export class DistributorComponent implements OnInit {

    @ViewChild('sweetAlertDistributor', {static: false}) private sweetAlertDistributor: SwalComponent;

    postulantForm = new FormGroup({
        commercial_name: new FormControl('', [Validators.required]),
        social_reason: new FormControl('', [Validators.required]),
        rfc: new FormControl('', [Validators.required]),
        logo: new FormControl(''),
        company_email: new FormControl('', [Validators.required, Validators.email]),
        company_phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
        company_postal_code: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern("^[0-9]*$")]),
        company_state: new FormControl('', [Validators.required]),
        company_city: new FormControl('', [Validators.required]),
        company_street_number: new FormControl('', [Validators.required]),
        company_colony: new FormControl('', [Validators.required]),
        company_capacity: new FormControl('', [Validators.required]),
        contact_name: new FormControl('', [Validators.required]),
        contact_email: new FormControl('', [Validators.required, Validators.email]),
        contact_phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
        contact_position: new FormControl('', [Validators.required]),
    });

    base64File: string = '';

    constructor(private router: Router, private toastr: ToastrService, private applicantService: ApplicantsService) { }

    ngOnInit() {
    }

    saveApplicant() {
        const formData = new FormData();
        formData.append("commercial_name", this.postulantForm.get('commercial_name').value);
        formData.append("social_reason", this.postulantForm.get('social_reason').value);
        formData.append("rfc", this.postulantForm.get('rfc').value);
        formData.append("logo", this.base64File);
        formData.append("email", this.postulantForm.get('company_email').value);
        formData.append("phone", this.postulantForm.get('company_phone').value);
        formData.append("postal_code", this.postulantForm.get('company_postal_code').value);
        formData.append("state", this.postulantForm.get('company_state').value);
        formData.append("city", this.postulantForm.get('company_city').value);
        formData.append("street_number", this.postulantForm.get('company_street_number').value);
        formData.append("colony", this.postulantForm.get('company_colony').value);
        formData.append("contact_name", this.postulantForm.get('contact_name').value);
        formData.append("contact_email", this.postulantForm.get('contact_email').value);
        formData.append("contact_phone", this.postulantForm.get('contact_phone').value);
        formData.append("contact_position", this.postulantForm.get('contact_position').value);
        formData.append("minimum_purchase", this.postulantForm.get('company_capacity').value);

        this.applicantService.applicant_distributor_add(formData).subscribe(res => {
            this.sweetAlertDistributor.fire();
            this.postulantForm.reset();
        }, error => {
            this.toastr.error("Error", "Ha ocurrido un error");
        });
    }

    onFileChange(e) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        var pattern = /image-*/;
        var reader = new FileReader();
        if(!file){
            this.base64File = '';
            return
        }
        if (!file.type.match(pattern)) {
          this.toastr.warning("Formato inválido.");
          this.base64File = '';
          return;
        }
        if(file.size > 1000000){
            this.toastr.warning("La imagen es muy grande, máximo 1MB.");
            this.base64File = '';
            $("#nombrearchivo").val("");
            return
        }
    
        reader.onload = () => {
            this.base64File =reader.result as string; 
        }
        reader.readAsDataURL(file);
        $("#nombrearchivo").val(file.name);
    }

    validateForm(){
        var dict = {
            commercial_name: "nombre de la empresa",
            social_reason: "razón social",
            rfc: "rfc",
            logo: "logo",
            company_email: "correo",
            company_phone: "teléfono",
            company_postal_code: "código postal",
            company_state: "estado",
            company_city: "ciudad",
            company_street_number: "calle y número",
            company_colony: "colonia",
            company_capacity: "capacidad mensual",
            contact_name: "nombre",
            contact_email: "correo",
            contact_phone: "teléfono",
            contact_position: "puesto",
        }
        if(!this.postulantForm.valid){
            Object.keys(this.postulantForm.controls).forEach(key => {
                const controlErrors: ValidationErrors = this.postulantForm.get(key).errors;
                if (controlErrors != null) {
                    Object.keys(controlErrors).forEach(keyError => {
                        this.toastr.error("El campo "+dict[key]+" es requerido");
                    });
                }
            });
        }
    }
}
