import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickModule } from 'ngx-slick';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule } from '@angular/common/http';

import { DataTablesModule } from 'angular-datatables';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgSwitcheryModule } from "angular-switchery-ios";
import { NgSelect2Module } from 'ng-select2';

import { PortalRoutingModule } from './portal-routing.module';
import { PortalComponent } from './portal.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeComponent } from './containers/home/home.component';
import { ContactUsComponent } from './containers/contact-us/contact-us.component';
import { ProductDetailComponent } from './containers/product-detail/product-detail.component';
import { DistributorComponent } from './containers/distributor/distributor.component';
import { TeamComponent } from './containers/team/team.component';
import { HistoryComponent } from './containers/history/history.component';
import { UsComponent } from './containers/us/us.component';
import { ProductsComponent } from './containers/products/products.component';
import { ChangePasswordComponent } from './containers/change-password/change-password.component';
import { PurchasesListComponent } from './containers/purchases-list/purchases-list.component';
import { PurchaseDetailComponent } from './containers/purchase-detail/purchase-detail.component';
import { InvoicesListComponent } from './containers/invoices-list/invoices-list.component';
import { PersistenceModule } from 'angular-persistence';
import { ProfileComponent } from './containers/profile/profile.component';
import { CartComponent } from './containers/cart/cart.component';
import { CheckoutComponent } from './containers/checkout/checkout.component';
import { CatalogComponent } from './containers/catalog/catalog.component';
import { ProductSearchComponent } from './containers/product-search/product-search.component';
import { NoticeOfPrivacyComponent } from './containers/notice-of-privacy/notice-of-privacy.component';


@NgModule({
  declarations: [
    PortalComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    ContactUsComponent,
    ProductDetailComponent,
    DistributorComponent,
    TeamComponent,
    HistoryComponent,
    UsComponent,
    ProductsComponent,
    ChangePasswordComponent,
    PurchasesListComponent,
    PurchaseDetailComponent,
    InvoicesListComponent,
    ProfileComponent,
    CartComponent,
    CheckoutComponent,
    CatalogComponent,
    ProductSearchComponent,
    NoticeOfPrivacyComponent,
  ],
  imports: [
    CommonModule,
    HttpClientJsonpModule,
    PortalRoutingModule,
    SlickModule.forRoot(),
    NgxImageZoomModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    PersistenceModule,
    SweetAlert2Module.forRoot(),
    NgSwitcheryModule,
    NgSelect2Module,
  ],
  providers:[]
})
export class PortalModule { }
