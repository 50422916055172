import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';
import { PurchasesService } from '../../services/purchases.service';
import { ApplicantsService } from '../../services/applicants.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ApplicantDistribution } from '../../models/applicant';
import { DatePipe } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  purchases_list: any;
  applicants_list: any;
  applicant_detail: ApplicantDistribution;
  apiUrl: string = environment.apiUrl;
  applicant_id: number;
  applicant_action: string;

  initOptionsChartPie = {
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    color: [
      '#2ec7c9','#b6a2de','#5ab1ef','#ffb980','#d87a80',
      '#8d98b3','#e5cf0d','#97b552','#95706d','#dc69aa',
      '#07a2a4','#9a7fd1','#588dd5','#f5994e','#c05050',
      '#59678c','#c9ab00','#7eb00a','#6f5553','#c14089'
    ],
    title: {
      text: 'Ventas por productos',
      left: 'center',
      textStyle: {
          fontSize: 17,
          fontWeight: 500
      },
      padding: 2
    },
    tooltip: {
      trigger: 'item',
      backgroundColor: 'rgba(0,0,0,0.75)',
      textStyle: {
          fontSize: 13,
          fontFamily: 'Roboto, sans-serif'
      },
      formatter: "{a} <br/>{b}: {c} ({d}%)"
    },
    series: [{
      name: 'Vendidos',
      type: 'pie',
      radius: ['50%', '70%'],
      center: ['50%', '57.5%'],
      itemStyle: {
          normal: {
              borderWidth: 1,
              borderColor: '#fff'
          }
      },
      data: []
    }]
  };
  optionsChartPie: any;

  initOptionsChartLine = {
    textStyle: {
      fontFamily: 'Roboto, Arial, Verdana, sans-serif',
      fontSize: 13
    },
    title: {
      text: 'Compras en los últimos 7 días',
      left: 'center',
      textStyle: {
          fontSize: 17,
          fontWeight: 500
      },
      padding: 2
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(0,0,0,0.75)',
      padding: [10, 15],
      textStyle: {
          fontSize: 13,
          fontFamily: 'Roboto, sans-serif'
      }
    },
    xAxis: {
        type: 'category',
        data: []
    },
    yAxis: {
        type: 'value'
    },
    series: [{
        data: [],
        type: 'line'
    }]
  };
  optionsChartLine: any;


  constructor(private purchaseService: PurchasesService, private applicantService: ApplicantsService, private auth: AuthService, private router: Router, private toastr: ToastrService, public datepipe: DatePipe ) { }

  ngOnInit() {
    this.get_purchases_latest();
    this.applicant_detail = new ApplicantDistribution();
    this.get_applicants_latest();
    this.get_top_products();
    this.get_purchases_latest_count();
  }

  get_purchases_latest(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.purchaseService.purchases_latest().subscribe( res =>{
      this.purchases_list = res;
      $.unblockUI();
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  get_top_products(){
    this.purchaseService.top_products().subscribe(res => {
        this.optionsChartPie = {
          series: [{
            data: res
          }]
        };
    }, error => {
        if(error['status'] == 401){
            this.router.navigateByUrl('/auth/panel_login');
            this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión");
            this.auth.clearToken();
        }else {
          this.toastr.error("Error");
        }
    });
  }

  get_purchases_latest_count(){
    var date = new Date();
    var dateArray = [];
    var countArray = [];

    this.purchaseService.purchases_latest_count().subscribe(res => {
      var counts: any = [];
      counts = res;
      var d = new Date();
      var found:any;
      for(let x=6;x>=1;x--){
        d = new Date();
        d.setDate(date.getDate() - x)
        dateArray.push(this.datepipe.transform(d, 'dd/MM/yyyy'));
        found = counts.find(count => count['created_at'] === this.datepipe.transform(d, 'yyyy-MM-dd'));
        countArray.push(found ? found['count'] : 0);
      }
      dateArray.push(this.datepipe.transform(date, 'dd/MM/yyyy'));
      found = counts.find(count => count['created_at'] === this.datepipe.transform(date, 'yyyy-MM-dd'));
      countArray.push(found ? found['count'] : 0);

      this.optionsChartLine = {
        xAxis: {
          data: dateArray
        },
        series: [{
          data: countArray
        }]
      };
    }, error => {
        if(error['status'] == 401){
            this.router.navigateByUrl('/auth/panel_login');
            this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión");
            this.auth.clearToken();
        }else {
          this.toastr.error("Error");
        }
    });
  }

  get_applicants_latest(){
    this.applicantService.applicants_distributor_latest().subscribe(res => {
        this.applicants_list = res;
    }, error => {
        if(error['status'] == 401){
            this.router.navigateByUrl('/auth/panel_login');
            this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión");
            this.auth.clearToken();
        }else {
          this.toastr.error("Error");
        }
    });
  }

  set_applicant_id(id: number){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.applicantService.applicant_distributor_detail(id).subscribe(res => {
        this.applicant_detail = res;
        $.unblockUI();
    });
}

set_applicant_action(id: number, action: string){
    this.applicant_id = id;
    this.applicant_action = action;
}

proccess_applicant(){
  $.blockUI({
    message: '<i class="icon-spinner4 spinner"></i>',
    overlayCSS: {
        backgroundColor: '#1b2024',
        opacity: 0.8,
        zIndex: 1200,
        cursor: 'wait'
    },
    css: {
        border: 0,
        color: '#fff',
        padding: 0,
        zIndex: 1201,
        backgroundColor: 'transparent'
    }
  });
  const data = {status_postulant: this.applicant_action}
  this.applicantService.applicant_distributor_update(this.applicant_id, data).subscribe(res =>{
      if(data.status_postulant == 'approved')
          this.toastr.success("El postulante ha sido aprobado");
      else
          this.toastr.success("El postulante ha sido rechazado");
      $.unblockUI();
      this.get_applicants_latest();
  }, error => {
      if(error['status'] == 401){
          this.router.navigateByUrl('/auth/panel_login');
          this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión");
          this.auth.clearToken();
      }else this.toastr.error("Error");
      $.unblockUI();
  });
}

}
