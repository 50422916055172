import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  reset_form = new FormGroup({
    passwd1: new FormControl('', [Validators.required]),
    passwd2: new FormControl('', [Validators.required])
  });
  uid: string;
  token: string;

  constructor(private authService: AuthService, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.uid = this.route.snapshot.paramMap.get('uid');
    this.token = this.route.snapshot.paramMap.get('token');
  }

  reset_password(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });

    if (this.reset_form.get('passwd1').value == this.reset_form.get('passwd2').value){
      this.authService.reset_password(this.uid, this.token, this.reset_form.value).subscribe(res => {
        $.unblockUI();
        this.toastr.success('Contraseña modificada correctamente');
        this.router.navigateByUrl('/');
      }, error => {
        console.log(error);
        $.unblockUI();
        this.toastr.warning(error['error'].message,'Error!');
      });
    }else{
      this.toastr.warning('Las contraseñas no coinciden');
      $.unblockUI();
    }    
    
  }

}
