import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistributorsService {

  apiURL: string = `${environment.apiUrl}/api/v1/distributors/`;

  constructor(private http: HttpClient) { }

  distributors_list(){
    return this.http.get(this.apiURL);
  }

  distributor_detail(id){
    return this.http.get<any>(`${this.apiURL}detail/${id}/`);
  }

  edit_distributor(id, data){
    return this.http.put<any>(`${this.apiURL}detail/${id}/`, data);
  }

  delete_distributor(id){
    return this.http.delete(`${this.apiURL}detail/${id}/`);
  }

}
