import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ProductsService } from '../../services/products.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';




declare var $: any;

@Component({
    selector: 'app-products-catalog-list',
    templateUrl: './products-catalog-list.component.html',
    styleUrls: ['./products-catalog-list.component.css']
})



export class ProductsCatalogListComponent implements OnInit{

    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger = new Subject();

    catalog_id: number;
    catalogList_english: any;
    catalogList_spanish:any;




    constructor(private product: ProductsService, private auth: AuthService, private router: Router, private toastr: ToastrService) {
        this.dtOptions = {
            "language": {
                "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json",
            },
            "order": [[0, "desc"]],
        };
    }

    ngOnInit() {
        this.getCatalogs();
    }

    getCatalogs() {
        $.blockUI({
            message: '<i class="icon-spinner4 spinner"></i>',
            overlayCSS: {
                backgroundColor: '#1b2024',
                opacity: 0.8,
                zIndex: 1200,
                cursor: 'wait'
            },
            css: {
                border: 0,
                color: '#fff',
                padding: 0,
                zIndex: 1201,
                backgroundColor: 'transparent'
            }
        });

        this.product.catalogs_list_english().subscribe(res => {
            this.catalogList_english = res;
        }),

        this.product.catalogs_list_spanish().subscribe(res => {
            this.catalogList_spanish = res;
            this.dtTrigger.next();
            $.unblockUI();
        }, error => {
            if (error['status'] == 401) {
                this.router.navigateByUrl('/auth/panel_login');
                this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
                this.auth.clearToken();
            } else {
                this.toastr.error("Error!");
                $.unblockUI();
            }
        })
       
    }
 
    set_catalog_id(id: number) {
        this.catalog_id = id;
    }

    delete_catalog() {
        this.product.delete_catalog(this.catalog_id).subscribe(res => {
            this.toastr.success("Cátalogo eliminado correctamente.");
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
            this.getCatalogs();
        });
    }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }



}

