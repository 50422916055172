import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DistributorsService } from '../../services/distributors.service';
import { AuthService } from '../../../auth/services/auth.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

declare var $: any;

@Component({
  selector: 'app-distributor-list',
  templateUrl: './distributor-list.component.html',
  styleUrls: ['./distributor-list.component.css']
})
export class DistributorListComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  distributors_list: any;
  distributor_id: number;

  constructor(private http: HttpClient, private distributorService: DistributorsService, private auth: AuthService,  private router: Router, private toastr: ToastrService) {
    this.dtOptions = {
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      }
    };
  }

  ngOnInit() {
    this.get_distributors_list();
  }

  get_distributors_list(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.distributorService.distributors_list().subscribe( res =>{
      this.distributors_list = res;
      this.dtTrigger.next();
      $.unblockUI();
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  set_distributor_id(id: number){
    this.distributor_id = id;
  }

  delete_distributor(){
    this.distributorService.delete_distributor(this.distributor_id).subscribe( res => {
      this.toastr.success("Distribuidor eliminado correctamente.");
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.get_distributors_list();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  exportExcel(){
    var today = new Date();
    var fileName = `ListaDistribuidores_${today.getDate()}-${today.getMonth()+1}-${today.getFullYear()}.xlsx`;
    var jsonData = this.distributors_list.map( d => {
      var distributors = {};
      distributors['Nombre comercial'] = d.distributor.commercial_name;
      distributors['Razón Social'] = d.distributor.social_reason;
      distributors['Correo'] = d.user.email;
      distributors['RFC'] = d.rfc;
      distributors['Ciudad'] = d.city;
      distributors['Estado'] = d.state;
      distributors['Código Postal'] = d.postal_code;
      distributors['Calle y número'] = d.street_number; 
      distributors['Colonia'] = d.colony;
      distributors['Nombre de contacto'] = d.distributor.contact_name;
      distributors['Correo de contacto'] = d.distributor.contact_email;
      distributors['Teléfono de contacto'] = d.distributor.contact_phone;
      distributors['Compra mínima'] = d.distributor.minimum_purchase;
      return distributors;
    });

    let title = 'Lista de Distribuidores';
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(title);

    let titleRow = worksheet.addRow([title]);
    titleRow.font = { family: 4, size: 16, bold: true };
    worksheet.addRow([]);
    worksheet.addRow([]);
    let headerRow = worksheet.addRow(Object.keys(jsonData[0]));
    headerRow.font = { family: 4, size: 12, bold: true };

    // Get logo
    this.http.get('/assets/portal/img/Fleximatic-logo-01.png', { responseType: 'blob' }).subscribe(res => {
      const reader = new FileReader();
      reader.onloadend = () => {
        var base64logo = reader.result.toString();
        var logo = workbook.addImage({
          base64: base64logo,
          extension: 'png',
        });
        worksheet.addImage(logo, 'F1:H3');
        // Download xlsx
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, `${fileName}.xlsx`);
        });
      }

      reader.readAsDataURL(res); 
    });
    
    worksheet.mergeCells('A1:D2');
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    jsonData.forEach(e => {
      worksheet.addRow(Object.values(e));
    });
  }

}
