import { AuthService } from './../../auth/services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class CheckoutGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router, private toastr: ToastrService){}

    canActivate(): boolean{
        if(this.authService.isAuth()){
            if (!localStorage.getItem('admin')) {
                return true;
            }else{
                this.authService.logout().subscribe(res => {
                    this.toastr.info("Debes iniciar sesión para continuar.");
                    this.router.navigateByUrl('/auth/login/checkout');
                });
                return false;
            }
        }else{
            this.toastr.info("Debes iniciar sesión para continuar.");
            this.router.navigateByUrl('/auth/login/checkout');
            return false;
        }
    }

}
