import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { InventoriesService } from '../../services/inventories.service';
import { ProductsService } from '../../services/products.service';
import { AuthService } from '../../../auth/services/auth.service';
import { environment } from '../../../../environments/environment';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

declare var $: any;
@Component({
  selector: 'app-inventories-list',
  templateUrl: './inventories-list.component.html',
  styleUrls: ['./inventories-list.component.css']
})
export class InventoriesListComponent implements OnInit, OnDestroy {
  @ViewChild('closeModal',{static: false}) closeModal: ElementRef;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  inventories_list: any;
  inventory_form: FormGroup = new FormGroup({
    name: new FormControl({value: '', disabled: true}),
    stock: new FormControl('', Validators.required),
    new_stock: new FormControl('', [Validators.min(1)]),
    total_stock: new FormControl({value: '', disabled: true})
  });
  inventory_obj: any;
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient, private inventory: InventoriesService, private product: ProductsService, private auth: AuthService,  private router: Router, private toastr: ToastrService) {
    this.dtOptions = {
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      }
    };
  }

  ngOnInit() {
    this.get_inventory_list();
    this.onChanges();
  }

  get_inventory_list(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.inventory.inventory_list().subscribe( res =>{
      this.inventories_list = res;
      this.dtTrigger.next();
      $.unblockUI();
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  get_inventory_detail(id: number){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.inventory_obj = null;
    this.inventory_form.setValue({name: '', stock: '', new_stock: '', total_stock: ''});

    this.inventory.inventory_detail(id).subscribe( res => {
      this.inventory_obj = res;
      this.inventory_form.get('name').setValue(this.inventory_obj.product.name);
      this.inventory_form.get('stock').setValue(this.inventory_obj.stock);
      this.inventory_form.get('total_stock').setValue(this.inventory_obj.stock);

      $.unblockUI();
    }, error => console.log(error));
  }

  onChanges(): void {
    this.inventory_form.get('new_stock').valueChanges.subscribe(val => {
      this.inventory_form.get('total_stock').setValue(  this.inventory_form.get('stock').value + val );
    });

    this.inventory_form.get('stock').valueChanges.subscribe(val => {
      this.inventory_form.get('total_stock').setValue(  this.inventory_form.get('new_stock').value + val );
    });
  }

  save_inventory(){
    let data = {
      product: this.inventory_obj.product.id, 
      stock: this.inventory_form.get('total_stock').value
    };

    this.inventory.edit_inventory( this.inventory_obj.product.id , data).subscribe( res => {
      this.toastr.success("Inventario actualizado correctamente.");
      this.closeModal.nativeElement.click();
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.get_inventory_list();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public exportExcel(): void {
    var today = new Date();
    var fileName = `ListaInventario_${today.getDate()}-${today.getMonth()+1}-${today.getFullYear()}.xlsx`;
    var jsonData = this.inventories_list.map( i => {
      var inventories = {};
      inventories['Código'] = i.product.code;
      inventories['Nombre'] = i.product.name;
      inventories['Existencia'] = i.stock;
      return inventories;
    });

    let title = 'Lista de Inventario';
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(title);

    let titleRow = worksheet.addRow([title]);
    titleRow.font = { family: 4, size: 16, bold: true };
    worksheet.addRow([]);
    worksheet.addRow([]);
    let headerRow = worksheet.addRow(Object.keys(jsonData[0]));
    headerRow.font = { family: 4, size: 12, bold: true };

    // Get logo
    this.http.get('/assets/portal/img/Fleximatic-logo-01.png', { responseType: 'blob' }).subscribe(res => {
      const reader = new FileReader();
      reader.onloadend = () => {
        var base64logo = reader.result.toString();
        var logo = workbook.addImage({
          base64: base64logo,
          extension: 'png',
        });
        worksheet.addImage(logo, 'F1:H3');
        // Download xlsx
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, `${fileName}.xlsx`);
        });
      }

      reader.readAsDataURL(res); 
    });
    
    worksheet.mergeCells('A1:C2');
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    jsonData.forEach(e => {
      worksheet.addRow(Object.values(e));
    });
  }

}
