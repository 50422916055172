import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { PanelLoginComponent } from './containers/panel-login/panel-login.component';
import { PanelAccountActivationComponent } from './containers/panel-account-activation/panel-account-activation.component';
import { RecoveryPasswordComponent } from './containers/recovery-password/recovery-password.component';
import { ResetPasswordComponent } from './containers/reset-password/reset-password.component';
import { PortalLoginComponent } from './containers/portal-login/portal-login.component';
import { RegisterClientComponent } from './containers/register-client/register-client.component';


const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
        {
        path: 'panel_login',
        component: PanelLoginComponent
        },
        {
          path: 'login',
          component: PortalLoginComponent
        },
        {
          path: 'login/:route',
          component: PortalLoginComponent
        },
        {
          path: 'register',
          component: RegisterClientComponent
        },
        {
          path: 'account_activation/:uid/:token',
          component: PanelAccountActivationComponent
        },
        {
          path: 'recovery_password',
          component: RecoveryPasswordComponent
        },
        {
          path: 'reset_password/:uid/:token',
          component: ResetPasswordComponent
        },
        {
            path: '**',
            redirectTo: 'login'
        }
        
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
