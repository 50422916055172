import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../auth/services/auth.service';
import { ProductCategoriesService } from '../../services/product-categories.service';

declare var $: any;
@Component({
  selector: 'app-product-categories-list',
  templateUrl: './product-categories-list.component.html',
  styleUrls: ['./product-categories-list.component.css']
})
export class ProductCategoriesListComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  product_categories_list: any;
  product_category_id: number;

  constructor(private productCategory: ProductCategoriesService, private auth: AuthService,  private router: Router, private toastr: ToastrService) {
    this.dtOptions = {
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      }
    };
  }

  ngOnInit() {
    this.get_product_categories_list();
  }

  get_product_categories_list(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.productCategory.product_categories_list().subscribe( res =>{
      this.product_categories_list = res;
      this.dtTrigger.next();
      $.unblockUI();
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/panel/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  set_product_category_id(id: number){
    this.product_category_id = id;
  }

  delete_product_category(){
    this.productCategory.delete_product_category(this.product_category_id).subscribe( res => {
      this.toastr.success("Categoría de producto eliminada correctamente.");
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.get_product_categories_list();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
