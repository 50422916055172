import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    year: number = new Date().getFullYear();
    mailChimpEndpoint: string = 'https://fleximatic.us4.list-manage.com/subscribe/post-json?u=b83d9690962c4067fe5258c02&amp;id=871aa76bbd&';

    emailControl = new FormControl('', [
        Validators.required,
        Validators.email,
    ]);

    constructor(private http: HttpClient, private toastr: ToastrService) { }

    ngOnInit() {
        this.executeJS()
    }

    executeJS(){
        $(document).ready(function(){
            var hccid=42198167;
            var nt=document.createElement("script");
            nt.async=true;
            nt.src="https://mylivechat.com/chatinline.aspx?hccid="+hccid;
            var ct=document.getElementsByTagName("script")[0];
            ct.parentNode.insertBefore(nt,ct);
        });
    }

    subscribe() {
        if (this.emailControl.valid) {
            const params = new HttpParams()
                .set('EMAIL', this.emailControl.value)
                .set('b_b83d9690962c4067fe5258c02_871aa76bbd', '');

            const mailChimpUrl = this.mailChimpEndpoint + params.toString();

            this.http.jsonp(mailChimpUrl, 'c').subscribe(response => {
                this.toastr.success('¡Gracias por suscribirse!');
                this.emailControl.setValue('');
            }, error => {
                console.error(error);
                this.toastr.error('Error');
            });
        }
    }

    scrollTop(){
        window.scrollTo(0,0);
    }

}
