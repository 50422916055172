import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LandingPagesService } from '../../services/landing-pages.service';
import { AuthService } from '../../../auth/services/auth.service';
import { environment } from '../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-landing-page-list',
  templateUrl: './landing-page-list.component.html',
  styleUrls: ['./landing-page-list.component.css']
})
export class LandingPageListComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  landing_page_list_english: any;
  landing_page_list_spanish:any;

  landing_page_id: number;
  apiUrl: string = environment.apiUrl;

  constructor(private landingpageService: LandingPagesService, private auth: AuthService,  private router: Router, private toastr: ToastrService) {
    this.dtOptions = {
      "language": {
          "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json",
      },
      "order": [[0, "desc"]],
  };
  }

  ngOnInit() {
    this.get_landing_page_list();
  }

  get_landing_page_list(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });

    this.landingpageService.landing_page_list_spanish().subscribe( res =>{
      this.landing_page_list_spanish = res;
    });

    this.landingpageService.landing_page_list_english().subscribe( res =>{
      this.landing_page_list_english = res;
      this.dtTrigger.next();
      $.unblockUI();
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  set_landing_page_id(id: number){
    this.landing_page_id = id;
  }

  delete_landing_page(){
    this.landingpageService.delete_landing_page(this.landing_page_id).subscribe( res => {
      this.toastr.success("Landing page eliminada correctamente.");
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.get_landing_page_list();
    }, error => {
      this.toastr.warning(error.error);
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
