import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    apiURL: string = `${environment.apiUrl}/api/v1/contactus/`;

    constructor(private http: HttpClient) { }

    sendEmail(data){
        return this.http.post(`${this.apiURL}send/`, data);
    }
}
