import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ToastrModule } from 'ngx-toastr';
import { AuthModule } from './auth/auth.module';
import { PanelModule } from './panel/panel.module';
import { PortalModule } from './portal/portal.module';
import { Http404Component } from './http404/http404/http404.component';
import { LandingPagesComponent } from './landing-pages/landing-pages.component';
import { DatePipe } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    Http404Component,
    LandingPagesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AuthModule,
    PanelModule,
    PortalModule,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
