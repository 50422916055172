import { Component, OnInit } from '@angular/core';
import { ContactService } from '../portal/services/contact.service';
import { LandingPagesService } from '../panel/services/landing-pages.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { LandingPage } from '../panel/models/landing_page';
import { HttpParams, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-landing-pages',
  templateUrl: './landing-pages.component.html',
  styleUrls: ['./landing-pages.component.css']
})
export class LandingPagesComponent implements OnInit {

  contactForm = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    city: new FormControl(''),
    phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
    email: new FormControl('', [Validators.required, Validators.email]),
    message: new FormControl('', [Validators.required]),
  });

  mailChimpEndpoint: string = 'https://fleximatic.us4.list-manage.com/subscribe/post-json?u=b83d9690962c4067fe5258c02&amp;id=871aa76bbd&';

  emailControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  apiUrl: string = environment.apiUrl;
  url: string;
  landing_page_obj: LandingPage = new LandingPage();

  constructor(private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private contactService: ContactService, private landingpageService: LandingPagesService, private http: HttpClient) { }

  ngOnInit() {
    this.url = this.route.snapshot.paramMap.get('url');
    this.landingpageService.landing_page_by_url(this.url).subscribe(res => {
      this.landing_page_obj = res;
    }, error => {
      this.toastr.error(error['error'].detail, "Error!");
      this.router.navigateByUrl('/');
    });
  }

  sendEmail(desicion) {
    const formData = new FormData();
    formData.append("nombre_contactanos", this.contactForm.get('first_name').value);
    formData.append("apellido_contactanos", this.contactForm.get('last_name').value);
    formData.append("ciudad_contactanos", this.contactForm.get('city').value);
    formData.append("telefono_contactanos", this.contactForm.get('phone').value);
    formData.append("correo_contactanos", this.contactForm.get('email').value);
    formData.append("descripcion_contactanos", this.contactForm.get('message').value);

    if (desicion) {
      this.contactService.sendEmail(formData).subscribe(res => {
        this.toastr.success("Sent", "An email has been sent, someone will be in touch soon")
      }, error => {
        console.log(error.error);
        this.toastr.error("Error", "An error has occurred");
      })
    }
    else { 
      this.contactService.sendEmail(formData).subscribe(res => {
        this.toastr.success("Enviado", "Se ha enviado un correo, alguien se pondrá en contacto pronto")
      }, error => {
        console.log(error.error);
        this.toastr.error("Error", "Ha ocurrido un error");
      })

    }




  }

  validateForm(desicion) {
    var dict = {
      first_name: "nombre",
      last_name: "apellido",
      phone: "teléfono",
      email: "correo",
      message: "asunto",
    }

    var dict_ing = {
      first_name: "first name",
      last_name: "last name",
      phone: "telephone",
      email: "email",
      message: "affair",
    }
    
    if(desicion){
      if (!this.contactForm.valid) {
        Object.keys(this.contactForm.controls).forEach(key => {
          const controlErrors: ValidationErrors = this.contactForm.get(key).errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              this.toastr.error("The " + dict_ing[key] + " field is required");
            });
          }
        });
      }
    }
    else{
      if (!this.contactForm.valid) {
        Object.keys(this.contactForm.controls).forEach(key => {
          const controlErrors: ValidationErrors = this.contactForm.get(key).errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              this.toastr.error("El campo " + dict[key] + " es requerido");
            });
          }
        });
      }

    }
    
    
  }

  subscribe(desicion) {

    if(desicion){
      if (this.emailControl.valid) {
        const params = new HttpParams()
          .set('EMAIL', this.emailControl.value)
          .set('b_b83d9690962c4067fe5258c02_871aa76bbd', '');
  
        const mailChimpUrl = this.mailChimpEndpoint + params.toString();
  
        this.http.jsonp(mailChimpUrl, 'c').subscribe(response => {
          this.toastr.success('Thanks for subscribing!');
          this.emailControl.setValue('');
        }, error => {
          console.error(error);
          this.toastr.error('Error');
        });
      }
    }
    else{
      if (this.emailControl.valid) {
        const params = new HttpParams()
          .set('EMAIL', this.emailControl.value)
          .set('b_b83d9690962c4067fe5258c02_871aa76bbd', '');
  
        const mailChimpUrl = this.mailChimpEndpoint + params.toString();
  
        this.http.jsonp(mailChimpUrl, 'c').subscribe(response => {
          this.toastr.success('¡Gracias por suscribirse!');
          this.emailControl.setValue('');
        }, error => {
          console.error(error);
          this.toastr.error('Error');
        });
      }
    }
  }


}
