import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;
@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.css']
})
export class RecoveryPasswordComponent implements OnInit {
  
  recovery_form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private authService: AuthService, private toastr: ToastrService, private location: Location, private router: Router) { }

  ngOnInit() {
  }

  recovery_password(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });

    this.authService.recovery_password(this.recovery_form.value).subscribe(res => {
      $.unblockUI();
      this.toastr.success('Correo enviado');
      this.recovery_form.reset();
    }, error => {
      console.log(error);
      $.unblockUI();
      this.toastr.warning(error['error'].message);
    });
  }

  goBack(){
    this.location.back();
  }

}
