import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InvoicesService } from '../../services/invoices.service';
import { ToastrService } from 'ngx-toastr';
import { Purchase } from '../../models/purchase';

declare var $: any;
@Component({
  selector: 'app-invoice-add',
  templateUrl: './invoice-add.component.html',
  styleUrls: ['./invoice-add.component.css']
})
export class InvoiceAddComponent implements OnInit {

  purchase_id: string;
  purchase_detail: Purchase;
  invoice_pdf: any;
  invoice_xml: any;

  constructor(private invoiceService: InvoicesService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    this.purchase_detail = new Purchase();
    this.purchase_detail.client = {user: { first_name: '', last_name: '' } };
  }

  search_purchase(){
    this.purchase_detail = new Purchase();
    this.purchase_detail.client = {user: { first_name: '', last_name: '' } };
    this.invoice_pdf = '';
    this.invoice_xml = '';

    if (this.purchase_id){
      $.blockUI({ 
        message: '<i class="icon-spinner4 spinner"></i>',
        overlayCSS: {
            backgroundColor: '#1b2024',
            opacity: 0.8,
            zIndex: 1200,
            cursor: 'wait'
        },
        css: {
            border: 0,
            color: '#fff',
            padding: 0,
            zIndex: 1201,
            backgroundColor: 'transparent'
        }
      });
      this.invoiceService.search_purchase(this.purchase_id).subscribe( res =>{
        this.purchase_detail = res;
        $.unblockUI();
      }, error => {
        this.toastr.warning("Compra no encontrada, ingrese un ID de compra válido.", "Error!");
        $.unblockUI();
      });
    }else{
      this.toastr.warning("Ingrese un ID de compra válido.");
    }
    
  }

  onFilePDFChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if(!file){
      this.invoice_pdf = '';
      return;
    }
    var pattern = 'application/pdf';
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      return;
    }

    reader.onload = () => {
      this.invoice_pdf = reader.result;
    }
    reader.readAsDataURL(file);
  }

  onFileXMLChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if(!file){
      this.invoice_xml = '';
      return;
    }
    var pattern = 'text/xml';
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      return;
    }

    reader.onload = () => {
      this.invoice_xml = reader.result;
    }
    reader.readAsDataURL(file);
  }

  add_invoice(){
    if(!this.invoice_pdf || !this.invoice_xml){
      this.toastr.warning("Seleccione los archivos de factura.");
      return;
    }
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    const data = {
      sale_order: this.purchase_detail.id,
      pdf_file: this.invoice_pdf,
      xml_file: this.invoice_xml
    };
    this.invoiceService.add_invoice(data).subscribe(res =>{
      this.router.navigateByUrl('/panel/invoices');
      this.toastr.success("Factura agregada correctamente.");
      $.unblockUI();
    }, error =>{
      console.log(error);
      this.toastr.error('Error!')
      $.unblockUI();
    })
  }

}
