import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ClientsService } from '../../services/clients.service';
import { AuthService } from '../../../auth/services/auth.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

declare var $: any;

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.css']
})
export class ClientsListComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  clients_list: any;
  client_id: number;

  constructor(private http: HttpClient, private clientService: ClientsService, private auth: AuthService,  private router: Router, private toastr: ToastrService) {
    this.dtOptions = {
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      }
    };
  }

  ngOnInit() {
    this.get_clients_list();
  }

  get_clients_list(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.clientService.clients_list().subscribe( res =>{
      this.clients_list = res;
      this.dtTrigger.next();
      $.unblockUI();
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  set_client_id(id: number){
    this.client_id = id;
  }

  delete_client(){
    this.clientService.delete_client(this.client_id).subscribe( res => {
      this.toastr.success("Cliente eliminado correctamente.");
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.get_clients_list();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  exportExcel(){
    var today = new Date();
    var fileName = `ListaClientes_${today.getDate()}-${today.getMonth()+1}-${today.getFullYear()}.xlsx`;
    var jsonData = this.clients_list.map( c => {
      var clients = {};
      clients['Nombre'] = `${c.user.first_name} ${c.user.last_name}`;
      clients['Correo'] = c.user.email;
      clients['Teléfono'] = c.user.phone;
      clients['Ciudad'] = c.city;
      clients['Estado'] = c.state;
      clients['Código Postal'] = c.postal_code;
      clients['Calle y número'] = c.street_number; 
      clients['Colonia'] = c.colony;
      return clients;
    });

    let title = 'Lista de Clientes';
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(title);

    let titleRow = worksheet.addRow([title]);
    titleRow.font = { family: 4, size: 16, bold: true };
    worksheet.addRow([]);
    worksheet.addRow([]);
    let headerRow = worksheet.addRow(Object.keys(jsonData[0]));
    headerRow.font = { family: 4, size: 12, bold: true };

    // Get logo
    this.http.get('/assets/portal/img/Fleximatic-logo-01.png', { responseType: 'blob' }).subscribe(res => {
      const reader = new FileReader();
      reader.onloadend = () => {
        var base64logo = reader.result.toString();
        var logo = workbook.addImage({
          base64: base64logo,
          extension: 'png',
        });
        worksheet.addImage(logo, 'F1:H3');
        // Download xlsx
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, `${fileName}.xlsx`);
        });
      }

      reader.readAsDataURL(res); 
    });
    
    worksheet.mergeCells('A1:D2');
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    jsonData.forEach(e => {
      worksheet.addRow(Object.values(e));
    });
  }

}
