import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelRoutingModule } from './panel-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DataTablesModule } from 'angular-datatables';
import { NgSwitcheryModule } from "angular-switchery-ios";
import { NgSelect2Module } from 'ng-select2';
import { NgxEchartsModule } from 'ngx-echarts';

import { PanelComponent } from './panel.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { SlidesListComponent } from './containers/slides-list/slides-list.component';
import { SlidesFormComponent } from './containers/slides-form/slides-form.component';
import { ShippingMethodsFormComponent } from './containers/shipping-methods-form/shipping-methods-form.component';
import { ShippingMethodsListComponent } from './containers/shipping-methods-list/shipping-methods-list.component';
import { ProductCategoriesListComponent } from './containers/product-categories-list/product-categories-list.component';
import { ProductCategoriesFormComponent } from './containers/product-categories-form/product-categories-form.component';
import { BrandsFormComponent } from './containers/brands-form/brands-form.component';
import { BrandsListComponent } from './containers/brands-list/brands-list.component';
import { ProductsListComponent } from './containers/products-list/products-list.component';
import { ProductsFormComponent } from './containers/products-form/products-form.component';
import { InventoriesFormComponent } from './containers/inventories-form/inventories-form.component';
import { InventoriesListComponent } from './containers/inventories-list/inventories-list.component';
import { UsersListComponent } from './containers/users-list/users-list.component';
import { UsersFormComponent } from './containers/users-form/users-form.component';
import { ApplicantsListComponent } from './containers/applicants-list/applicants-list.component';
import { ApplicantsDistributionListComponent } from './containers/applicants-distribution-list/applicants-distribution-list.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { PromotionsListComponent } from './containers/promotions-list/promotions-list.component';
import { PromotionsFormComponent } from './containers/promotions-form/promotions-form.component';
import { ChangePasswordComponent } from './containers/change-password/change-password.component';
import { PurchasesListComponent } from './containers/purchases-list/purchases-list.component';
import { PurchasesDetailComponent } from './containers/purchases-detail/purchases-detail.component';
import { ClientsListComponent } from './containers/clients-list/clients-list.component';
import { DistributorListComponent } from './containers/distributor-list/distributor-list.component';
import { DistributorFormComponent } from './containers/distributor-form/distributor-form.component';
import { DistributorPurchaseHistoryComponent } from './containers/distributor-purchase-history/distributor-purchase-history.component';
import { ClientPurchaseHistoryComponent } from './containers/client-purchase-history/client-purchase-history.component';
import { InvoicesListComponent } from './containers/invoices-list/invoices-list.component';
import { InvoiceAddComponent } from './containers/invoice-add/invoice-add.component';
import { ProductsCatalogFormComponent } from './containers/products-catalog-form/products-catalog-form.component';
import { ProductsCatalogListComponent } from './containers/products-catalog-list/products-catalog-list.component';
import { LandingPageListComponent } from './containers/landing-page-list/landing-page-list.component';
import { LandingPageFormComponent } from './containers/landing-page-form/landing-page-form.component';
import { UserRolesListComponent } from './containers/user-roles-list/user-roles-list.component';
import { UserRolesFormComponent } from './containers/user-roles-form/user-roles-form.component';
import * as echarts from 'echarts';
import { SidebarDynamicButonComponent } from './containers/sidebar-dynamic-buton/sidebar-dynamic-buton.component';
import { SidebarDynamicButonFromComponent } from './containers/sidebar-dynamic-buton-from/sidebar-dynamic-buton-from.component';



@NgModule({
  declarations: [
    PanelComponent,
    NavbarComponent,
    SidebarComponent,
    DashboardComponent,
    SlidesListComponent,
    SlidesFormComponent,
    ShippingMethodsFormComponent,
    ShippingMethodsListComponent,
    ProductCategoriesListComponent,
    ProductCategoriesFormComponent,
    BrandsFormComponent,
    BrandsListComponent,
    ProductsListComponent,
    ProductsFormComponent,
    InventoriesFormComponent,
    InventoriesListComponent,
    UsersListComponent,
    UsersFormComponent,
    ApplicantsListComponent,
    ApplicantsDistributionListComponent,
    ProfileComponent,
    PromotionsListComponent,
    PromotionsFormComponent,
    ChangePasswordComponent,
    PurchasesListComponent,
    PurchasesDetailComponent,
    ClientsListComponent,
    DistributorListComponent,
    DistributorFormComponent,
    DistributorPurchaseHistoryComponent,
    ClientPurchaseHistoryComponent,
    InvoicesListComponent,
    InvoiceAddComponent,
    ProductsCatalogFormComponent,
    ProductsCatalogListComponent,
    LandingPageListComponent,
    LandingPageFormComponent,
    UserRolesListComponent,
    UserRolesFormComponent,
    SidebarDynamicButonComponent,
    SidebarDynamicButonFromComponent,


  ],
  imports: [
    CommonModule,
    PanelRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgSwitcheryModule,
    NgSelect2Module,
    NgxEchartsModule.forRoot({
      echarts: { init: echarts.init }
    })
  ]
})
export class PanelModule { }
