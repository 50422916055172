export class Applicant {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    address: string;
    phone: string;
    curriculum: string;
    status_postulant: string;
}

export class ApplicantDistribution{
    id: number;
    rfc: string;
    commercial_name: string;
    social_reason: string;
    street_number: string;
    colony: string;
    city: string;
    state: string;
    postal_code: number;
    contact_name: string;
    contact_address: string;
    contact_phone: string;
    logo: string;
    is_distribuitor: boolean;
    minimum_purchase: string;
    status_postulant: string;
}