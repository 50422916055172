import { CheckoutGuard } from './guards/checkout.guard';
import { CheckoutComponent } from './containers/checkout/checkout.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortalComponent } from './portal.component';
import { ContactUsComponent } from './containers/contact-us/contact-us.component';
import { HistoryComponent } from './containers/history/history.component';
import { TeamComponent } from './containers/team/team.component';
import { DistributorComponent } from './containers/distributor/distributor.component';
import { UsComponent } from './containers/us/us.component';
import { HomeComponent } from './containers/home/home.component';
import { ProductDetailComponent } from './containers/product-detail/product-detail.component';
import { ProductsComponent } from './containers/products/products.component';
import { ChangePasswordComponent } from './containers/change-password/change-password.component';
import { PortalGuard } from './guards/portal.guard';
import { PurchasesListComponent } from './containers/purchases-list/purchases-list.component';
import { PurchaseDetailComponent } from './containers/purchase-detail/purchase-detail.component';
import { InvoicesListComponent } from './containers/invoices-list/invoices-list.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { CartComponent } from './containers/cart/cart.component';
import { CatalogComponent } from './containers/catalog/catalog.component';
import { ProductSearchComponent } from './containers/product-search/product-search.component';
import { NoticeOfPrivacyComponent } from './containers/notice-of-privacy/notice-of-privacy.component';


const routes: Routes = [
    {
        path: '',
        component: PortalComponent,
        children: [
            { path: '', component: HomeComponent },
            { path: 'us', component: UsComponent },
            { path: 'products/:type/:page', component: ProductsComponent },
            { path: 'products/:type/:id/:page', component: ProductsComponent },
            { path: ':type/detail/:id', component: ProductDetailComponent },
            { path: 'distribution', component: DistributorComponent },
            { path: 'team', component: TeamComponent },
            { path: 'history', component: HistoryComponent },
            { path: 'contact_us', component: ContactUsComponent },
            { path: 'products/detail/:id', component: ProductDetailComponent},
            { path: 'profile', component : ProfileComponent, canActivate: [PortalGuard] },
            { path: 'change_password', component : ChangePasswordComponent, canActivate: [PortalGuard] },
            { path: 'purchases', component : PurchasesListComponent, canActivate: [PortalGuard] },
            { path: 'purchase/:id', component : PurchaseDetailComponent, canActivate: [PortalGuard] },
            { path: 'invoices', component : InvoicesListComponent, canActivate: [PortalGuard] },
            { path: 'ecommerce/cart', component: CartComponent},
            { path: 'ecommerce/checkout', component: CheckoutComponent, canActivate:[CheckoutGuard]},
            { path: 'catalog/:type', component: CatalogComponent}, 
            { path: 'catalog/:type/:page', component: CatalogComponent},
            { path: 'catalog/:type/:category/:page', component: CatalogComponent},
            { path: ':type/detail/:id', component: ProductDetailComponent },
            { path: 'product_search/:search/:page1/:page2', component: ProductSearchComponent },
            {path:'aviso-de-privacidad', component: NoticeOfPrivacyComponent},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PortalRoutingModule { }
