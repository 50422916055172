import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { LandingPagesService } from '../../services/landing-pages.service';
import { ToastrService } from 'ngx-toastr';
import { LandingPage } from '../../models/landing_page';
import { environment } from '../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-landing-page-form',
  templateUrl: './landing-page-form.component.html',
  styleUrls: ['./landing-page-form.component.css']
})
export class LandingPageFormComponent implements OnInit {

  landing_page_form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    url: new FormControl('', [Validators.required]),
    main_image: new FormControl(''),
    main_text: new FormControl('', [Validators.required]),
    intermediate_text: new FormControl('', [Validators.required]),
    intermediate_image: new FormControl(''),
    is_active: new FormControl('true', Validators.required),
    sections: this.formBuilder.array([ this.createSection() ]),
    is_English: new FormControl('', [Validators.required])
  });

  section_form = new FormGroup({
    image: new FormControl(''),
    title: new FormControl('', [Validators.required]),
    text: new FormControl('', [Validators.required]),
    button_text: new FormControl('', [Validators.required]),
    button_url: new FormControl('', [Validators.required]),
  });

  form_type: string;
  id: string;
  landing_page_obj: any;
  section_id: number;
  section_obj: any;
  apiUrl: string = environment.apiUrl;

  constructor(private landingpageService: LandingPagesService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.landing_page_obj = new LandingPage();
    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id == 'add'){
      this.form_type = 'Agregar';
      $.unblockUI();
    }else{
      this.form_type = 'Editar';
      this.landingpageService.landing_page_detail(this.id).subscribe( res =>{
        var sections = res['sections'];
        delete res['id'];
        this.landing_page_obj = res;
        this.landing_page_obj.sections = [this.createSection().value];
        this.landing_page_form.setValue(this.landing_page_obj);
        this.landing_page_obj.sections = sections;
        if (sections.length >= 3){
          (<FormArray>this.landing_page_form.controls['sections']).clear();
        }
        this.landing_page_form.get('main_image').setValue('');
        this.landing_page_form.get('intermediate_image').setValue('');
        $.unblockUI();
      }, error => {
        $.unblockUI();
        this.router.navigateByUrl('/panel/landing_pages');
        this.toastr.error(error['error'].detail, "Error!");
      });
    }

  }

  createSection(): FormGroup {
    return this.formBuilder.group({
      image: '',
      title: '',
      text: '',
      button_text: '',
      button_url: ''
    });
  }

  add_section(){
    let sections = (<FormArray>this.landing_page_form.controls['sections']);

    if (sections.length + this.landing_page_obj.sections.length < 3){
      sections.push( this.createSection() );
    }else
      this.toastr.warning("Máximo 3 secciones para landing page.")
  }

  remove_section(index) {
    let section = (<FormArray>this.landing_page_form.controls['sections']);
    if (section.length > 1){
      section.removeAt(index);
    }
  }

  set_section_id(id: number){
    this.section_id = id;
  }

  delete_landing_page_section(){
    this.landingpageService.delete_landing_page_section(this.section_id).subscribe( res => {
      this.toastr.success("Sección eliminada correctamente.");
      this.landing_page_obj.sections.splice(this.landing_page_obj.sections.findIndex(s => s.id == this.section_id),1);
    });
  }

  edit_section(){
    let formData = new FormData();
    formData.append("landing_page", this.section_obj.landing_page);
    for(let control in this.section_form.controls){
      formData.append(control, this.section_form.get(control).value);
    }
    
    this.landingpageService.edit_landing_page_section(this.section_obj.id, formData).subscribe( res => {
      this.toastr.success("Sección modificada correctamente.");
      var index = this.landing_page_obj.sections.findIndex(s => s.id == this.section_id);
      this.landing_page_obj.sections[index] = res;
      $('#modal_edit').modal('hide');
    });
  }

  get_section_detail(id: number){
    this.section_id = id;
    this.section_obj = this.landing_page_obj.sections.find(s => s.id == id);
    this.section_form.get('image').setValue('');
    this.section_form.get('title').setValue(this.section_obj.title);
    this.section_form.get('text').setValue(this.section_obj.text);
    this.section_form.get('button_text').setValue(this.section_obj.button_text);
    this.section_form.get('button_url').setValue(this.section_obj.button_url);
  }

  onSectionImageChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      this.section_form.get('image').setValue('');
      return;
    }

    reader.onload = () => {
      this.section_form.get('image').setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  onMainImageChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      this.landing_page_form.get('main_image').setValue('');
      return;
    }

    reader.onload = () => {
      this.landing_page_form.get('main_image').setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  onInterImageChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      this.landing_page_form.get('intermediate_image').setValue('');
      return;
    }

    reader.onload = () => {
      this.landing_page_form.get('intermediate_image').setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  onImageChange(e, index) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      (<FormArray>this.landing_page_form.get('sections')).at(index).get('image').setValue('');
      return;
    }

    reader.onload = () => {
      (<FormArray>this.landing_page_form.get('sections')).at(index).get('image').setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  save_landing_page(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });

    const formData = new FormData();
    for(let control in this.landing_page_form.controls){
      formData.append(control, this.landing_page_form.get(control).value);
    }
    
    if (this.form_type == 'Agregar') {
      this.landingpageService.add_landing_page(formData).subscribe( res => {
        // Add section
        let formData = new FormData();
        formData.append("landing_page", res['id']);
        for( let section of this.landing_page_form.get('sections').value){
          if (section){
            formData.set("title", section.title);
            formData.set("text", section.text);
            formData.set("image", section.image);
            formData.set("button_text", section.button_text);
            formData.set("button_url", section.button_url);
            this.landingpageService.add_landing_page_section(formData).subscribe();
          }
        }
        $.unblockUI();
        this.router.navigateByUrl('/panel/landing_pages');
        this.toastr.success("Landing page creada correctamente.");

      }, error => {
        $.unblockUI();
        for (var key in error.error) {
          this.toastr.error(error.error[key][0], "Error!");
        }
      });

    }else if(this.form_type == 'Editar'){
      this.landingpageService.edit_landing_page(this.id,formData).subscribe( res => {
        // Add section
        let formData = new FormData();
        formData.append("landing_page", res['id']);
        for( let section of this.landing_page_form.get('sections').value){
          if (section){
            formData.set("title", section.title);
            formData.set("text", section.text);
            formData.set("image", section.image);
            formData.set("button_text", section.button_text);
            formData.set("button_url", section.button_url);
            this.landingpageService.add_landing_page_section(formData).subscribe();
          }
        }
        $.unblockUI();
        this.router.navigateByUrl('/panel/landing_pages');
        this.toastr.success("Landing page modificada correctamente.");
      }, error => {
        $.unblockUI();
        for (var key in error.error) {
          this.toastr.error(error.error[key][0], "Error!");
        }
      });
    }

  }

}
