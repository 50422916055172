import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-user-roles-form',
  templateUrl: './user-roles-form.component.html',
  styleUrls: ['./user-roles-form.component.css']
})
export class UserRolesFormComponent implements OnInit {

  user_role_form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    is_active: new FormControl('true', Validators.required)
  });

  form_type: string;
  id: string;
  user_role_obj: any;
  user_role_permissions: any;
  permissions: any;
  apiUrl: string = environment.apiUrl;
  menus: any;

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    $('.listbox').bootstrapDualListbox({
      nonSelectedListLabel:"Permisos",
      selectedListLabel:"Permisos asignados",
      infoText:'Total permisos {0}',
      infoTextEmpty:'Lista vacía',
      filterTextClear:'Mostrar todos',
      filterPlaceHolder:'Buscar',
      infoTextFiltered:'<span class="badge badge-warning">Filtrado</span> {0} de {1}'
    });

    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'add'){
      this.form_type = 'Agregar';
      this.get_menu_list(false);
    }else{
      this.form_type = 'Editar';
      this.auth.user_role_detail(this.id).subscribe( res =>{
        delete res['id'];
        this.user_role_permissions = res['user_role_permissions'];
        delete res['user_role_permissions'];
        this.get_menu_list(true);
        this.user_role_obj = res;
        this.user_role_form.setValue(this.user_role_obj);
      }, error => {
        this.router.navigateByUrl('/panel/user_roles');
        this.toastr.error(error['error'].detail, "Error!");
      });

    }
  }

  get_menu_list(edit: boolean){
    this.auth.menu_list().subscribe( res => {
      this.menus = res;
      for(let menu of this.menus){
        var selected: string = '';
        if(menu['has_submenu']){
          for(let submenu of menu['submenus']){
            if(edit){
              if ( this.user_role_permissions.some( perm => { return(perm['menu']['id'] == menu['id'] && perm['submenu']['id'] == submenu['id']) }) ){
                selected = 'selected';
              }
            }
            $('.listbox').append('<option value="' + menu['id'] + '|' + submenu['id'] + '"' + selected + '>' + menu['title'] + ' - ' + submenu['title'] +'</option>');
          }
        }else{
          if(edit){
            if ( this.user_role_permissions.some( perm => { return perm['menu']['id'] == menu['id'] }) ){
              selected = 'selected';
            }
          }
          $('.listbox').append('<option value="' + menu['id'] + '|0"' + selected + '>' + menu['title'] +'</option>');
        }
      }
      $('.listbox').trigger('bootstrapDualListbox.refresh', true);
    });
  }

  get_permissions(){
    this.permissions = [];
    var perms = $('.listbox').val();
    for(let perm of perms){
      var p = perm.split("|");
      p[1] == 0 ? p[1] = null : p[1]
      this.permissions.push({menu: p[0], submenu: p[1]});
    }
    return this.permissions;
  }

  save_user_role(){
    const data = {
      name: this.user_role_form.get('name').value,
      user_role_permissions: this.get_permissions(),
      is_active: this.user_role_form.get('is_active').value
    }

    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    if (this.form_type == 'Agregar') {
      this.auth.add_user_role(data).subscribe( res => {
        $.unblockUI();
        this.router.navigateByUrl('/panel/user_roles');
        this.toastr.success("Rol de usuario creado correctamente.");
      }, error => {
        let errors = error['error'];
        for (let e in errors) {
          this.toastr.error(errors[e],"Error!");
        }
        $.unblockUI();
      });

    }else if(this.form_type == 'Editar'){
      this.auth.edit_user_role(this.id,data).subscribe( res => {
        $.unblockUI();
        this.router.navigateByUrl('/panel/user_roles');
        this.toastr.success("Rol de usuario modificado correctamente.");
      }, error => {
        console.log(error);
        let errors = error['error'];
        for (let e in errors) {
          this.toastr.error(errors[e],"Error!");
        }
        $.unblockUI();
      });
    }
    
  }

}
