import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { BrandsService } from '../../services/brands.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-brands-form',
  templateUrl: './brands-form.component.html',
  styleUrls: ['./brands-form.component.css']
})
export class BrandsFormComponent implements OnInit {

  brand_form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    link: new FormControl(''),
    logo: new FormControl(''),
    is_active: new FormControl('true', Validators.required)
  });

  form_type: string;
  id: string;
  brand_obj: any;
  apiUrl: string = environment.apiUrl;

  constructor(private brand: BrandsService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'add'){
      this.form_type = 'Agregar';
      this.brand_form.get('logo').setValidators(Validators.required);
    }else{
      this.form_type = 'Editar';
      this.brand.brand_detail(this.id).subscribe( res =>{
        delete res['id'];
        this.brand_obj = res;
        this.brand_form.setValue(this.brand_obj);
        this.brand_form.get('logo').setValue('');
      }, error => {
        this.router.navigateByUrl('/panel/brands');
        this.toastr.error(error['error'].detail, "Error!");
      });

    }
  }

  onFileChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      return;
    }

    reader.onload = () => {
      this.brand_form.get('logo').setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  save_brand(){
    const formData = new FormData();
    formData.append("name", this.brand_form.get('name').value);
    formData.append("link", this.brand_form.get('link').value);
    formData.append("logo", this.brand_form.get('logo').value);
    formData.append("is_active", this.brand_form.get('is_active').value);

    if (this.form_type == 'Agregar') {
      this.brand.add_brand(formData).subscribe( res => {
        this.router.navigateByUrl('/panel/brands');
        this.toastr.success("Marca creada correctamente.");
      }, error => {
        console.log(error);
        this.toastr.error("Error!");
      });

    }else if(this.form_type == 'Editar'){
      this.brand.edit_brand(this.id,formData).subscribe( res => {
        this.router.navigateByUrl('/panel/brands');
        this.toastr.success("Marca modificada correctamente.");
      }, error => {
        console.log(error);
        this.toastr.error("Error!");
      });
    }
    
  }

}
