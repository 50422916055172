import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,  Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../../auth/services/auth.service';
import { ClientsService } from '../../../panel/services/clients.service'
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profile_form = new FormGroup({
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('',[Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$"),]),
    avatar: new FormControl(''),
    commercial_name: new FormControl(''),
    social_reason: new FormControl(''),
    minimum_purchase: new FormControl(''),
    rfc: new FormControl(''),
    street_number: new FormControl(''),
    postal_code: new FormControl('', [Validators.minLength(5), Validators.maxLength(5), Validators.pattern("^[0-9]*$"),]),
    state: new FormControl(''),
    city: new FormControl(''),
    colony: new FormControl(''),
    contact_name: new FormControl(''),
    contact_email: new FormControl('', Validators.email),
    contact_position: new FormControl(''),
    contact_phone: new FormControl('',[Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$"),]),
  });

  address_form = new FormGroup({
    id: new FormControl(''),
    street_number: new FormControl('', Validators.required),
    postal_code: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern("^[0-9]*$"),]),
    state: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    colony: new FormControl('', Validators.required)
  });

  profile_obj: any = { user: ''};
  apiUrl: string = environment.apiUrl;
  shipping_address_id: number;
  shipping_address_obj: any;
  method_type: string = "Agregar";

  constructor(private authService: AuthService, private clientService: ClientsService, private router: Router, private toastr: ToastrService) { }

  ngOnInit() {
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.authService.client_profile().subscribe( res => {
      this.profile_obj = res;

      this.profile_form.get('first_name').setValue(this.profile_obj.user.first_name);
      this.profile_form.get('last_name').setValue(this.profile_obj.user.last_name);
      this.profile_form.get('rfc').setValue(this.profile_obj.rfc);
      this.profile_form.get('email').setValue(this.profile_obj.user.email);
      this.profile_form.get('phone').setValue(this.profile_obj.user.phone);
      this.profile_form.get('street_number').setValue(this.profile_obj.street_number);
      this.profile_form.get('postal_code').setValue(this.profile_obj.postal_code);
      this.profile_form.get('state').setValue(this.profile_obj.state);
      this.profile_form.get('city').setValue(this.profile_obj.city);
      this.profile_form.get('colony').setValue(this.profile_obj.colony);
      this.profile_form.get('avatar').setValue('');

      if (this.profile_obj.is_distributor){
        this.profile_form.get('commercial_name').setValue(this.profile_obj.distributor.commercial_name);
        this.profile_form.get('social_reason').setValue(this.profile_obj.distributor.social_reason);
        this.profile_form.get('minimum_purchase').setValue(this.profile_obj.distributor.minimum_purchase);
        this.profile_form.get('contact_name').setValue(this.profile_obj.distributor.contact_name);
        this.profile_form.get('contact_email').setValue(this.profile_obj.distributor.contact_email);
        this.profile_form.get('contact_position').setValue(this.profile_obj.distributor.contact_position);
        this.profile_form.get('contact_phone').setValue(this.profile_obj.distributor.contact_phone);

        this.profile_form.get('commercial_name').setValidators([Validators.required]);
        this.profile_form.get('social_reason').setValidators([Validators.required]);
        this.profile_form.get('rfc').setValidators([Validators.required]);
        this.profile_form.get('minimum_purchase').setValidators([Validators.required]);
        this.profile_form.get('contact_name').setValidators([Validators.required]);
        this.profile_form.get('contact_email').setValidators([Validators.required]);
        this.profile_form.get('contact_position').setValidators([Validators.required]);
        this.profile_form.get('contact_phone').setValidators([Validators.required]);
      }else{
        this.profile_form.get('first_name').setValidators([Validators.required]);
        this.profile_form.get('last_name').setValidators([Validators.required]);
      }
      Object.keys(this.profile_form.controls).forEach(key => {
        this.profile_form.controls[key].updateValueAndValidity()
      });

      $.unblockUI();
    });
  }

  onFileChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      return;
    }

    reader.onload = () => {
      this.profile_form.get('avatar').setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  save_profile(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });

    this.authService.edit_client_profile(this.profile_form.value).subscribe( res => {
      $.unblockUI();
      this.router.navigateByUrl('/');
      this.toastr.success("Perfil modificado correctamente.");
    }, () => {
      this.toastr.error("Ha ocurrido un error al actualizar el perfil del cliente.")
      $.unblockUI();
    });
    
  }

  save_shipping_address(){
    if(this.method_type == "Agregar"){
      this.clientService.add_shipping_address(this.address_form.value).subscribe( res => {
        this.toastr.success("Dirección de envío creada correctamente.");
        this.updateShippingAddress();
        $("#modal_form").modal("hide");
      }, error => {
        console.log(error);
      });
    }else if(this.method_type == "Editar"){
      this.clientService.edit_shipping_address(this.shipping_address_obj.id, this.address_form.value).subscribe( res => {
        this.toastr.success("Dirección de envío modificada correctamente.");
        this.updateShippingAddress();
        $("#modal_form").modal("hide");
      }, error => {
        console.log(error);
      });
    }
    
  }

  add_address(){
    this.method_type = "Agregar";
    this.address_form.reset();
  }

  set_shipping_address_id(id: number){
    this.shipping_address_id = id;
  }

  get_shipping_address_detail(id: number){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.shipping_address_obj = null;

    this.clientService.shipping_address_detail(id).subscribe( res => {
      this.method_type = "Editar";
      delete res['client'];
      this.shipping_address_obj = res;
      this.address_form.setValue(this.shipping_address_obj);

      $.unblockUI();
    }, error => console.log(error));
  }

  delete_shipping_address(){
    this.clientService.delete_shipping_address(this.shipping_address_id).subscribe( res => {
      this.toastr.success("Dirección de envío eliminada correctamente.");
      this.updateShippingAddress();
    });
  }

  updateShippingAddress(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.authService.client_profile().subscribe( res => {
      this.profile_obj = res;
      $.unblockUI();
    });
  }

}
