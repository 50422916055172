import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PanelComponent } from './panel.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { ShippingMethodsListComponent } from './containers/shipping-methods-list/shipping-methods-list.component';
import { ShippingMethodsFormComponent } from './containers/shipping-methods-form/shipping-methods-form.component';
import { ProductCategoriesListComponent } from './containers/product-categories-list/product-categories-list.component';
import { ProductCategoriesFormComponent } from './containers/product-categories-form/product-categories-form.component';
import { SlidesListComponent } from './containers/slides-list/slides-list.component';
import { SlidesFormComponent } from './containers/slides-form/slides-form.component';
import { BrandsListComponent } from './containers/brands-list/brands-list.component';
import { BrandsFormComponent } from './containers/brands-form/brands-form.component';
import { ProductsListComponent } from './containers/products-list/products-list.component';
import { ProductsFormComponent } from './containers/products-form/products-form.component';
import { InventoriesListComponent } from './containers/inventories-list/inventories-list.component';
import { InventoriesFormComponent } from './containers/inventories-form/inventories-form.component';
import { UsersListComponent } from './containers/users-list/users-list.component';
import { UsersFormComponent } from './containers/users-form/users-form.component';
import { ApplicantsListComponent } from './containers/applicants-list/applicants-list.component';
import { ApplicantsDistributionListComponent } from './containers/applicants-distribution-list/applicants-distribution-list.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { PromotionsListComponent } from './containers/promotions-list/promotions-list.component';
import { PromotionsFormComponent } from './containers/promotions-form/promotions-form.component';
import { ChangePasswordComponent } from './containers/change-password/change-password.component';
import { PurchasesListComponent } from './containers/purchases-list/purchases-list.component';
import { PurchasesDetailComponent } from './containers/purchases-detail/purchases-detail.component';
import { ClientsListComponent } from './containers/clients-list/clients-list.component';
import { DistributorListComponent } from './containers/distributor-list/distributor-list.component';
import { DistributorFormComponent } from './containers/distributor-form/distributor-form.component';
import { DistributorPurchaseHistoryComponent } from './containers/distributor-purchase-history/distributor-purchase-history.component';
import { ClientPurchaseHistoryComponent } from './containers/client-purchase-history/client-purchase-history.component';
import { InvoicesListComponent } from './containers/invoices-list/invoices-list.component';
import { InvoiceAddComponent } from './containers/invoice-add/invoice-add.component';
import { ProductsCatalogFormComponent } from './containers/products-catalog-form/products-catalog-form.component';
import { ProductsCatalogListComponent } from './containers/products-catalog-list/products-catalog-list.component';
import { LandingPageListComponent } from './containers/landing-page-list/landing-page-list.component';
import { LandingPageFormComponent } from './containers/landing-page-form/landing-page-form.component';
import { UserRolesListComponent } from './containers/user-roles-list/user-roles-list.component';
import { UserRolesFormComponent } from './containers/user-roles-form/user-roles-form.component';
import { SidebarDynamicButonComponent } from './containers/sidebar-dynamic-buton/sidebar-dynamic-buton.component';
import { SidebarDynamicButonFromComponent } from './containers/sidebar-dynamic-buton-from/sidebar-dynamic-buton-from.component';


const routes: Routes = [
    {
        path: '',
        component: PanelComponent,
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'change_password',
                component: ChangePasswordComponent
            },
            {
                path: 'shipping_methods',
                component: ShippingMethodsListComponent
            },
            {
                path: 'shipping_method/:id',
                component: ShippingMethodsFormComponent
            },
            {
                path: 'product_categories',
                component: ProductCategoriesListComponent
            },
            {
                path: 'product_category/:id',
                component: ProductCategoriesFormComponent
            },
            {
                path: 'brands',
                component: BrandsListComponent
            },
            {
                path: 'brand/:id',
                component: BrandsFormComponent
            },
            {
                path: 'promotions',
                component: PromotionsListComponent
            },
            {
                path: 'promotion/:id',
                component: PromotionsFormComponent
            },
            {
                path: 'products',
                component: ProductsListComponent
            },
            {
                path: 'product/:id',
                component: ProductsFormComponent
            },
            {
                path: 'products/catalog',
                component: ProductsCatalogListComponent
            },
            {
                path: 'products/catalog/add',
                component: ProductsCatalogFormComponent
            },
            {
                path: 'inventories',
                component: InventoriesListComponent
            },
            {
                path: 'inventory/:id',
                component: InventoriesFormComponent
            },
            {
                path: 'users',
                component: UsersListComponent
            },
            {
                path: 'user/:id',
                component: UsersFormComponent
            },
            {
                path: 'user_roles',
                component: UserRolesListComponent
            },
            {
                path: 'user_role/:id',
                component: UserRolesFormComponent
            },
            {
                path: 'applicants',
                component: ApplicantsListComponent
            },
            {
                path: 'applicants_distribution',
                component: ApplicantsDistributionListComponent
            },
            {
                path: 'clients',
                component: ClientsListComponent
            },
            {
                path: 'client_purchase_history/:id',
                component: ClientPurchaseHistoryComponent
            },
            {
                path: 'distributors',
                component: DistributorListComponent
            },
            {
                path: 'distributor/:id',
                component: DistributorFormComponent
            },
            {
                path: 'distributor_purchase_history/:id',
                component: DistributorPurchaseHistoryComponent
            },
            {
                path: 'purchases',
                component: PurchasesListComponent
            },
            {
                path: 'purchase/:id',
                component: PurchasesDetailComponent
            },
            {
                path: 'invoices',
                component: InvoicesListComponent
            },
            {
                path: 'invoice/add',
                component: InvoiceAddComponent
            },
            {
                path: 'slides',
                component: SlidesListComponent
            },
            {
                path: 'slide/:id',
                component: SlidesFormComponent
            },
            {
                path: 'landing_pages',
                component: LandingPageListComponent
            },
            {
                path: 'landing_page/:id',
                component: LandingPageFormComponent
            },
            {
                path: 'dynamic_buton',
                component: SidebarDynamicButonComponent
            },
            {
                path: 'dynamic_buton/add',
                component: SidebarDynamicButonFromComponent
            },
            {
                path: '**',
                redirectTo: 'dashboard'
            }
        ]
    }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PanelRoutingModule { }
