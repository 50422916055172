import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiURL: string = `${environment.apiUrl}/auth/`;

  userToken: string;
  refreshToken: string;

  constructor(private http: HttpClient) { }

  users_list(){
    return this.http.get(`${this.apiURL}users/`);
  }

  admin_users_list(){
    return this.http.get(`${this.apiURL}admin_users/`);
  }

  user_detail(id){
    return this.http.get(`${this.apiURL}user/${id}/`);
  }

  add_user(user){
    return this.http.post(`${this.apiURL}create/`, user);
  }

  edit_user(id, user){
    return this.http.put(`${this.apiURL}user/${id}/`, user);
  }

  delete_user(id){
    return this.http.delete(`${this.apiURL}user/${id}/`);
  }

  activate_account(uid,token){
    return this.http.get(`${this.apiURL}activation/${uid}/${token}/`);
  }

  user_avatar(url){
    return this.http.get(`${environment.apiUrl}${url}/`, {responseType: "blob"});
  }

  user_profile(){
    return this.http.get<any>(`${this.apiURL}profile/`);
  }

  client_profile(){
    return this.http.get<any>(`${this.apiURL}my_profile/`);
  }

  edit_client_profile(data){
    return this.http.put(`${this.apiURL}my_profile/`, data);
  }

  change_password(data){
    return this.http.post(`${this.apiURL}password/change/`, data);
  }

  recovery_password(data){
    return this.http.post(`${this.apiURL}password/recovery/`, data);
  }

  reset_password(uid, token, data){
    return this.http.post(`${this.apiURL}reset_password/${uid}/${token}/`, data);
  }

  user_roles_list(){
    return this.http.get(`${this.apiURL}user_roles/`);
  }

  user_role_detail(id){
    return this.http.get(`${this.apiURL}user_roles/${id}/`);
  }

  add_user_role(user_role){
    return this.http.post(`${this.apiURL}user_roles/add/`, user_role);
  }

  edit_user_role(id, user_role){
    return this.http.put(`${this.apiURL}user_roles/${id}/`, user_role);
  }

  delete_user_role(id){
    return this.http.delete(`${this.apiURL}user_roles/${id}/`);
  }

  menu_list(){
    return this.http.get(`${environment.apiUrl}/api/v1/menus/`);
  }

  user_permissions_list(){
    return this.http.get(`${this.apiURL}user_permissions/current/`);
  }

  admin_login(user){
    return this.http.post(`${this.apiURL}login_admin/`, user).pipe(
      map(res => {
        if (res['access_token']){
          this.saveTokens(res);
          localStorage.setItem('admin','true');
        }
        return res;
      })
    );
  }

  client_login(user){
    return this.http.post(`${this.apiURL}login_customer/`, user).pipe(
      map(res => {
        if (res['access_token']){
          this.saveTokens(res);
          localStorage.setItem('distributor',res['distributor']);
        }
        return res;
      })
    );
  }

  logout(){
    let token: Object = { token: this.readToken() };
    return this.http.post(`${this.apiURL}logout/`, token).pipe(
      map( res => {
        this.clearToken();
        return res;
      })
    );
  }

  refresh_token(){
    let refresh_token: Object = { refresh_token: this.refreshToken };
    return this.http.post(`${this.apiURL}token/refresh/`, refresh_token).pipe(
      map( res => {
        console.log(res);
        return res;
      })
    );
  }

  private saveTokens(data){
    this.userToken = data['access_token'];
    this.refreshToken = data['refresh_token'];
    let expire = new Date();
    expire.setSeconds( Number(data['expires_in']));

    localStorage.setItem('token',this.userToken);
    localStorage.setItem('refresh_token',this.refreshToken);
    localStorage.setItem('expire',expire.getTime().toString());
  }

  readToken(){
    if ( localStorage.getItem('token') ){
      this.userToken = localStorage.getItem('token');
      this.refreshToken = localStorage.getItem('refresh_token');
    }else{
      this.userToken = '';
      this.refreshToken = '';
    }
    return this.userToken;
  }

  isAuth(): boolean{
    this.readToken();
    if ( this.userToken.length < 15){
      this.clearToken();
      return false;
    }

    const expire = Number(localStorage.getItem('expire'));
    const expireDate = new Date();
    expireDate.setTime(expire);

    if ( expireDate > new Date() ){
      return true;
    }else{
      return false;
    }

  }

  clearToken(){
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expire');
    localStorage.removeItem('admin');
    localStorage.removeItem('distributor');
    this.userToken = '';
    this.refreshToken = '';
  }
}
