import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ProductsService } from '../../services/products.service';
import { AuthService } from '../../../auth/services/auth.service';
import { environment } from '../../../../environments/environment';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

declare var $: any;
@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.css']
})
export class ProductsListComponent implements OnInit {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  products_list: any;
  product_id: number;
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient, private product: ProductsService, private auth: AuthService,  private router: Router, private toastr: ToastrService) {
    this.dtOptions = {
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      }
    };
  }

  ngOnInit() {
    this.get_product_list();
  }

  get_product_list(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.product.products_list().subscribe( res =>{
      this.products_list = res;
      this.dtTrigger.next();
      $.unblockUI();
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  set_product_id(id: number){
    this.product_id = id;
  }

  delete_product(){
    this.product.delete_product(this.product_id).subscribe( res => {
      this.toastr.success("Producto eliminado correctamente.");
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.get_product_list();
    }, error => {
      this.toastr.warning(error.error);
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  public exportExcel(): void {
    var today = new Date();
    var fileName = `ListaProductos_${today.getDate()}-${today.getMonth()+1}-${today.getFullYear()}.xlsx`;
    var jsonData = this.products_list.map( p => {
      var products = {};
      products['Código'] = p.code;
      products['Nombre'] = p.name;
      products['Categoría'] = p.category.name;
      products['Marca'] = p.brand.name;
      products['Descripción'] = p.description;
      products['Precio cliente'] = p.customer_price;
      products['Precio distribuidor'] = p.distributor_price;
      products['Peso'] = p.weight;
      products['Capacidad máxima de compra'] = p.max_purchase;
      return products;
    });

    let title = 'Lista de Productos';
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(title);

    let titleRow = worksheet.addRow([title]);
    titleRow.font = { family: 4, size: 16, bold: true };
    worksheet.addRow([]);
    worksheet.addRow([]);
    let headerRow = worksheet.addRow(Object.keys(jsonData[0]));
    headerRow.font = { family: 4, size: 12, bold: true };

    // Get logo
    this.http.get('/assets/portal/img/Fleximatic-logo-01.png', { responseType: 'blob' }).subscribe(res => {
      const reader = new FileReader();
      reader.onloadend = () => {
        var base64logo = reader.result.toString();
        var logo = workbook.addImage({
          base64: base64logo,
          extension: 'png',
        });
        worksheet.addImage(logo, 'E1:G3');
        // Download xlsx
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, `${fileName}.xlsx`);
        });
      }

      reader.readAsDataURL(res); 
    });
    
    worksheet.mergeCells('A1:B2');
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    jsonData.forEach(e => {
      worksheet.addRow(Object.values(e));
    });
  }

}
