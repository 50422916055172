import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ApplicantsService {

    apiURL: string = `${environment.apiUrl}/api/v1/applicants/`;

    constructor(private http: HttpClient) {}

    applicants_team_list(){
        return this.http.get(`${this.apiURL}team/`);
    }

    applicant_add(data){
        return this.http.post(`${this.apiURL}register_team/add/`, data);
    }

    applicant_detail(id){
        return this.http.get<any>(`${this.apiURL}team/detail/${id}/`);
    }

    applicant_update(id, data){
        return this.http.put(`${this.apiURL}team/detail/${id}/`, data);
    }

    applicants_distributor_list(){
        return this.http.get(`${this.apiURL}distributor/`);
    }

    applicants_distributor_latest(){
        return this.http.get(`${this.apiURL}distributor/latest/`);
    }

    applicant_distributor_add(data){
        return this.http.post(`${this.apiURL}register_distributor/add/`, data);
    }

    applicant_distributor_detail(id){
        return this.http.get<any>(`${this.apiURL}distributor/detail/${id}/`);
    }

    applicant_distributor_update(id, data){
        return this.http.put(`${this.apiURL}distributor/detail/${id}/`, data);
    }
}
