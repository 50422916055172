import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private auth: AuthService, private router: Router){}

    canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot):boolean {
        if (!this.auth.isAuth()){
            return true;
        }else{
            if (state.url == '/auth/login' && localStorage.getItem('admin')){
                this.auth.logout().subscribe();
                return true;
            }else{
                this.router.navigateByUrl('/panel');
                return false;
            }
        }
    }

}
