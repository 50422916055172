import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { SlidesService } from '../../services/slides.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-slides-form',
  templateUrl: './slides-form.component.html',
  styleUrls: ['./slides-form.component.css']
})
export class SlidesFormComponent implements OnInit {

  slide_form = new FormGroup({
    header: new FormControl('', [Validators.required]),
    button_url: new FormControl('', [Validators.required]),
    image: new FormControl(''),
    is_active: new FormControl('true', Validators.required)
  });

  form_type: string;
  id: string;
  slide_obj: any;
  apiUrl: string = environment.apiUrl;

  constructor(private slide: SlidesService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'add'){
      this.form_type = 'Agregar';
      this.slide_form.get('image').setValidators(Validators.required);
    }else{
      this.form_type = 'Editar';
      this.slide.slide_detail(this.id).subscribe( res =>{
        delete res['id'];
        this.slide_obj = res;
        this.slide_form.setValue(this.slide_obj);
        this.slide_form.get('image').setValue('');
      }, error => {
        this.router.navigateByUrl('/panel/slides');
        this.toastr.error(error['error'].detail, "Error!");
      });

    }
  }

  onFileChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      return;
    }

    reader.onload = () => {
      this.slide_form.get('image').setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  save_slide(){
    const formData = new FormData();
    formData.append("header", this.slide_form.get('header').value);
    formData.append("button_url", this.slide_form.get('button_url').value);
    formData.append("image", this.slide_form.get('image').value);
    formData.append("is_active", this.slide_form.get('is_active').value);

    if (this.form_type == 'Agregar') {
      this.slide.add_slide(formData).subscribe( res => {
        this.router.navigateByUrl('/panel/slides');
        this.toastr.success("Slide creado correctamente.");
      }, error => {
        console.log(error);
        this.toastr.error("Error!");
      });

    }else if(this.form_type == 'Editar'){
      this.slide.edit_slide(this.id,formData).subscribe( res => {
        this.router.navigateByUrl('/panel/slides');
        this.toastr.success("Slide modificado correctamente.");
      }, error => {
        console.log(error);
        this.toastr.error("Error!");
      });
    }
    
  }

}
