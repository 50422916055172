import { Component, OnInit, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { PurchasesService } from '../../services/purchases.service';
import { ToastrService } from 'ngx-toastr';
import { Purchase } from '../../models/purchase';

declare var $: any;
@Component({
  selector: 'app-purchases-detail',
  templateUrl: './purchases-detail.component.html',
  styleUrls: ['./purchases-detail.component.css']
})
export class PurchasesDetailComponent implements OnInit {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  id: string;
  purchase_detail: Purchase;
  index_status: any = {
    'new': 0,
    'tracing': 1,
    'authorized': 2,
    'sent': 3,
    'closed': 4
  };
  purchase_status: any;

  constructor(private purchaseService: PurchasesService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService, private location: Location) {
    this.dtOptions = {
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      }
    };
   }

  ngOnInit() {
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.purchase_detail = new Purchase();
    this.purchase_detail.client = {user: { first_name: '', last_name: '' } };
    this.purchase_detail.shipping_method = {};
    
    this.id = this.route.snapshot.paramMap.get('id');
    this.purchaseService.purchase_detail(this.id).subscribe( res =>{
      this.purchase_detail = res;
      this.purchase_status = this.index_status[this.purchase_detail.purchase_status];
      this.dtTrigger.next();
      $.unblockUI();
    }, error => {
      this.router.navigateByUrl('/panel/purchases');
      this.toastr.error(error['error'].detail, "Error!");
      $.unblockUI();
    });
    
  }

  save_purchase(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    const data = {
      purchase_status: this.purchase_detail.purchase_status, 
      shipping_method: this.purchase_detail.shipping_method.id,
      shipping_cost: this.purchase_detail.shipping_cost,
      tracking_number: this.purchase_detail.tracking_number
    }
    this.purchaseService.purchase_edit(this.id, data).subscribe(res => {
      $.unblockUI();
      this.toastr.success('Compra actualizada correctamente.');
      this.purchase_detail = res;
      this.purchase_status = this.index_status[this.purchase_detail.purchase_status];
    }, error => {
      console.log(error);
      $.unblockUI();
      this.toastr.warning('Error al actualizar la compra.');
      this.toastr.warning(error.error);
    });
  }

  goBack(){
    this.location.back();
  }

}
