import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BrandsService {

  apiURL: string = `${environment.apiUrl}/api/v1/brands/`;

  constructor(private http: HttpClient) { }

  brands_list(){
    return this.http.get(this.apiURL);
  }

  brand_detail(id){
    return this.http.get(`${this.apiURL}${id}/`);
  }

  add_brand(data){
    return this.http.post(`${this.apiURL}add/`, data);
  }

  edit_brand(id, data){
    return this.http.put(`${this.apiURL}${id}/`, data);
  }

  delete_brand(id){
    return this.http.delete(`${this.apiURL}${id}/`);
  }

}
