import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  apiURL: string = `${environment.apiUrl}/api/v1/invoices/`;

  constructor(private http: HttpClient) { }

  invoices_list(){
    return this.http.get(this.apiURL);
  }

  invoice_detail(id){
    return this.http.get<any>(`${this.apiURL}detail/${id}/`);
  }

  add_invoice(data){
    return this.http.post(`${this.apiURL}add/`, data);
  }

  edit_invoice(id, data){
    return this.http.put<any>(`${this.apiURL}detail/${id}/`, data);
  }

  search_purchase(purchase_id){
    return this.http.get<any>(`${this.apiURL}search/${purchase_id}/`);
  }

  delete_invoice(id){
    return this.http.delete(`${this.apiURL}detail/${id}/`);
  }

}
