import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PurchasesService {

  apiURL: string = `${environment.apiUrl}/api/v1/shopping/`;

  constructor(private http: HttpClient) { }

  purchase_history(){
    return this.http.get(`${this.apiURL}history/`);
  }

  purchase_detail(id){
    return this.http.get<any>(`${this.apiURL}history/detail/${id}/`);
  }

  invoices_list(){
    return this.http.get(`${this.apiURL}my_invoices/`);
  }

}
