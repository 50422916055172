import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';

declare var $: any;
@Component({
  selector: 'app-panel-account-activation',
  templateUrl: './panel-account-activation.component.html',
  styleUrls: ['./panel-account-activation.component.css']
})
export class PanelAccountActivationComponent implements OnInit {
  uid: string;
  token: string;
  response: number;
  redirect: string;

  constructor(private auth: AuthService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.uid = this.route.snapshot.paramMap.get('uid');
    this.token = this.route.snapshot.paramMap.get('token');

    this.auth.activate_account(this.uid, this.token).subscribe(res =>{
      this.toastr.success(res['message']);
      $.unblockUI();
      if(res['user_type'] == 'admin'){
        this.redirect = '/auth/panel_login';
      }else if(res['user_type'] == 'client'){
        this.redirect = '/auth/login';
      }
      this.response = 1;
    }, error => {
      console.log(error);
      this.toastr.warning(error['error'].message);
      $.unblockUI();
      this.response = 0;
    });
  }

}
