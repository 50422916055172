import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DistributorsService } from '../../services/distributors.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';

declare var $: any;
@Component({
  selector: 'app-distributor-form',
  templateUrl: './distributor-form.component.html',
  styleUrls: ['./distributor-form.component.css']
})
export class DistributorFormComponent implements OnInit {

  distributor_form = new FormGroup({
    commercial_name: new FormControl(''),
    social_reason: new FormControl({value: '', disabled: true}),
    minimum_purchase: new FormControl('', [Validators.required]),
    rfc: new FormControl({value: '', disabled: true}, [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('',[Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$"),]),
    contact_name: new FormControl(''),
    contact_email: new FormControl('', [Validators.required, Validators.email]),
    contact_position: new FormControl(''),
    contact_phone: new FormControl('',[Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$"),]),
  });

  id: string;
  distributor_obj: any = {};
  apiUrl: string = environment.apiUrl;

  constructor(private distributorService: DistributorsService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.distributorService.distributor_detail(this.id).subscribe( res => {
      this.distributor_obj = res;

      this.distributor_form.get('commercial_name').setValue(this.distributor_obj.distributor.commercial_name);
      this.distributor_form.get('social_reason').setValue(this.distributor_obj.distributor.social_reason);
      this.distributor_form.get('minimum_purchase').setValue(this.distributor_obj.distributor.minimum_purchase);
      this.distributor_form.get('rfc').setValue(this.distributor_obj.rfc);
      this.distributor_form.get('email').setValue(this.distributor_obj.user.email);
      this.distributor_form.get('phone').setValue(this.distributor_obj.user.phone);
      this.distributor_form.get('contact_name').setValue(this.distributor_obj.distributor.contact_name);
      this.distributor_form.get('contact_email').setValue(this.distributor_obj.distributor.contact_email);
      this.distributor_form.get('contact_position').setValue(this.distributor_obj.distributor.contact_position);
      this.distributor_form.get('contact_phone').setValue(this.distributor_obj.distributor.contact_phone);
      
      $.unblockUI();
    });
  }

  save_distributor(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });

    this.distributorService.edit_distributor(this.id,this.distributor_form.value).subscribe( res => {
      $.unblockUI();
      this.router.navigateByUrl('/panel/distributors');
      this.toastr.success("Distribuidor modificado correctamente.");
    }, error => {
      let errors = error['error'];
      for (let e in errors) {
        this.toastr.error(errors[e],"Error!");
      }
      $.unblockUI();
    });

  }

}
