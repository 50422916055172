import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClientsService } from '../../../panel/services/clients.service';
import { ToastrService } from 'ngx-toastr';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

declare var $: any;

@Component({
  selector: 'app-register-client',
  templateUrl: './register-client.component.html',
  styleUrls: ['./register-client.component.css']
})
export class RegisterClientComponent implements OnInit {

  registerForm = new FormGroup({
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    email2: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('',[Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$"),])
  });

  constructor(private clientService: ClientsService, private toastr: ToastrService, private router: Router) { }

  @ViewChild('sweetAlertRegister', {static: false}) private sweetAlertRegister: SwalComponent;

  ngOnInit() {
  }

  register(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });

    if (this.registerForm.get('email').value != this.registerForm.get('email2').value){
      this.toastr.warning('Las email no coinciden');
      $.unblockUI();
    } else {
      this.clientService.add_client(this.registerForm.value).subscribe( res => {
        this.sweetAlertRegister.fire();
        $.unblockUI();
      }, error => {
        let errors = error['error'];
        for (let e in errors) {
          this.toastr.error(errors[e],"Error!");
        }
        $.unblockUI();
      });
    }

  }

  registerOk(){
    this.router.navigateByUrl('/');
  }

}
