import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ShippingMethodsService {

    apiURL: string = `${environment.apiUrl}/api/v1/shipping_methods/`;


    constructor(private http: HttpClient) { }

    shipping_methods_list() {
        return this.http.get(this.apiURL);
    }

    shipping_method_detail(id) {
        return this.http.get(`${this.apiURL}${id}/`);
    }

    add_shipping_method(data) {
        return this.http.post(`${this.apiURL}add/`, data);
    }

    edit_shipping_method(id, data) {
        return this.http.put(`${this.apiURL}${id}/`, data);
    }

    delete_shipping_method(id) {
        return this.http.delete(`${this.apiURL}${id}/`);
    }

    shipping_methods_clients_list(){
        return this.http.get(`${this.apiURL}customer_shipping/`);
    }

    add_price_by_weight(data) {
        return this.http.post(`${this.apiURL}price_by_weight/add/`, data);
    }

    delete_price_by_weight(id) {
        return this.http.delete(`${this.apiURL}price_by_weight/detail/${id}/`);
    }

    shipping_method_clients_detail(id){
        return this.http.get(`${this.apiURL}customer_shipping/detail/${id}`);
    }
}
