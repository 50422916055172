import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {DynamicButonService} from '../../services/dynamic-buton.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var $: any;


@Component({
  selector: 'app-sidebar-dynamic-buton-from',
  templateUrl: './sidebar-dynamic-buton-from.component.html',
  styleUrls: ['./sidebar-dynamic-buton-from.component.css']
})

export class SidebarDynamicButonFromComponent implements OnInit {

  Boton_form: FormGroup = new FormGroup({
    Titulo: new FormControl('', [Validators.required]),
    link: new FormControl('', [Validators.required]),
    status: new FormControl(true),
    is_active: new FormControl(true),
});


  constructor(private Dynamicbuton: DynamicButonService, private router: Router, private toastr: ToastrService) { }

  ngOnInit() {
  }

  save_button(){
    const formData = new FormData();
    formData.append("Titulo", this.Boton_form.get('Titulo').value);
    formData.append("link", this.Boton_form.get('link').value);
    formData.append("status", this.Boton_form.get('status').value);
    formData.append("is_active", this.Boton_form.get('is_active').value);

    this.Dynamicbuton.add_name_botton(formData).subscribe( res => {
        console.log(res);
        this.router.navigateByUrl('/panel/dynamic_buton');
        this.toastr.success("El nombre fue cambiado correctamente.");
    }, () => {
        $.unblockUI();
        this.toastr.error("Error!");
    });
}


}
