import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DynamicButonService {

  apiURL: string = `${environment.apiUrl}/api/v1/english_section/`;

  constructor(private http: HttpClient) { }

  botton_list(){
    return this.http.get(`${this.apiURL}dynamic/list`);
  }
  button_list_client(){
    return this.http.get(`${this.apiURL}dynamic/client`);
  }

  add_name_botton(data){
    return this.http.post(`${this.apiURL}dynamic/add/`, data);
  }

  delete_name_botton(id){
    return this.http.delete(`${this.apiURL}dynamic/${id}/`);
  }

  edit_name_botton(id, data){
    return this.http.put(`${this.apiURL}dynamic/${id}/`, data);
  }


}
