import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PortalComponent implements OnInit {
  user: any = {first_name: '', last_name: ''};
  apiUrl: string = environment.apiUrl;
  
  constructor(private auth: AuthService, private router: Router) {  }

  ngOnInit() {
    if (this.auth.isAuth()){
      if(!localStorage.getItem('admin')){
        this.auth.user_profile().subscribe( res => {
          this.user = res;
        });
      }
    }
    
  }

}
