import { environment } from './../../../../environments/environment';
import { SlidesService } from './../../../panel/services/slides.service';
import { BrandsService } from './../../../panel/services/brands.service';
import { CatalogService } from '../../services/catalog.service';
import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

    slideTitle: string = '';
    slideUrl: string = '';
    lastWord: string = '';
    slides: any;
    apiUrl: string = environment.apiUrl;
    slideConfig = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        autoplaySpeed: 5000,
        arrows: false,
        fade: true,
        fadeSpeed: 1000
    }
    slides2 : any;
    slideConfig2 = {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    }
    productCategories: any;


    constructor(private slidesService: SlidesService, private brandsService: BrandsService, private catalogService: CatalogService) { }

    ngOnInit() {
        this.getSlides();
        this.getBrands();
        this.getCategories();
    }

    afterChange(e) {

        var title = this.slides[e.currentSlide].header;
        var lastIndex = title.lastIndexOf(" ");
        this.slideTitle = title.substring(0, lastIndex);
        this.slideUrl = this.slides[e.currentSlide].button_url;
        
        this.lastWord = title.split(" ").splice(-1)[0];
    }

    getSlides(){
        this.slidesService.slides_list().subscribe(res => {
            this.slides = res;

            var title = res[0].header;
            var lastIndex = title.lastIndexOf(" ");
            this.slideTitle = title.substring(0, lastIndex);
            this.slideUrl = res[0].button_url;

            this.lastWord = title.split(" ").splice(-1)[0];
        });
    }

    getBrands(){
        this.brandsService.brands_list().subscribe(res => {
            this.slides2 = res;
        });
    }

    getCategories(){
        this.catalogService.getFeaturedCategoriesList().subscribe(res => {
            this.productCategories = res;
        });
    }

}
