import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PurchasesService {

  apiURL: string = `${environment.apiUrl}/api/v1/shopping/`;

  constructor(private http: HttpClient) { }

  purchases_list(){
    return this.http.get(this.apiURL);
  }

  purchases_latest(){
    return this.http.get(`${this.apiURL}latest/`);
  }

  purchases_closed(){
    return this.http.get(`${this.apiURL}closed/`);
  }
  
  top_products(){
    return this.http.get(`${this.apiURL}top_products/`);
  }

  purchases_latest_count(){
    return this.http.get(`${this.apiURL}latest_count/`);
  }

  purchase_detail(id){
    return this.http.get<any>(`${this.apiURL}detail/${id}/`);
  }

  purchase_edit(id, data){
    return this.http.put<any>(`${this.apiURL}detail/${id}/`, data);
  }

  purchase_history(client_distributor: number){
    // client 0, distributor 1
    return this.http.get(`${this.apiURL}history/${client_distributor}/`);
  }

  client_purchase_history(id){
    return this.http.get(`${this.apiURL}history/0/detail/${id}/`);
  }

  distributor_purchase_history(id){
    return this.http.get(`${this.apiURL}history/1/detail/${id}/`);
  }

}
