import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlidesService {

  apiURL: string = `${environment.apiUrl}/api/v1/slides/`;

  constructor(private http: HttpClient) { }

  slides_list(){
    return this.http.get(this.apiURL);
  }

  slide_detail(id){
    return this.http.get(`${this.apiURL}detail/${id}/`);
  }

  add_slide(data){
    return this.http.post(`${this.apiURL}add/`, data);
  }

  edit_slide(id, data){
    return this.http.put(`${this.apiURL}detail/${id}/`, data);
  }

  delete_slide(id){
    return this.http.delete(`${this.apiURL}detail/${id}/`);
  }

}
