import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  apiURL: string = `${environment.apiUrl}/api/v1/clients/`;

  constructor(private http: HttpClient) { }

  clients_list(){
    return this.http.get(this.apiURL);
  }

  client_detail(id){
    return this.http.get<any>(`${this.apiURL}${id}/`);
  }

  add_client(data){
    return this.http.post(`${this.apiURL}add/`, data);
  }

  edit_client(id, data){
    return this.http.put<any>(`${this.apiURL}${id}/`, data);
  }

  delete_client(id){
    return this.http.delete(`${this.apiURL}${id}/`);
  }

  add_shipping_address(data){
    return this.http.post(`${this.apiURL}shipping_address/add/`, data);
  }

  shipping_address_detail(id){
    return this.http.get<any>(`${this.apiURL}shipping_address/${id}/`);
  }

  edit_shipping_address(id, data){
    return this.http.put<any>(`${this.apiURL}shipping_address/${id}/`, data);
  }

  delete_shipping_address(id){
    return this.http.delete(`${this.apiURL}shipping_address/${id}/`);
  }
}
