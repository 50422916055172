import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { PromotionsService } from '../../services/promotions.service';
import { ProductsService } from '../../services/products.service';
import { ToastrService } from 'ngx-toastr';
import { Promotion } from '../../models/promotion';
import { Select2OptionData } from 'ng-select2';
import { environment } from '../../../../environments/environment';

declare var $: any;
@Component({
  selector: 'app-promotions-form',
  templateUrl: './promotions-form.component.html',
  styleUrls: ['./promotions-form.component.css']
})
export class PromotionsFormComponent implements OnInit {

  promotion_form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    start_date: new FormControl(''),
    finish_date: new FormControl(''),
    discount_rate: new FormControl('', [Validators.required, Validators.min(1), Validators.max(100)]),
    description: new FormControl(''),
    status_promotion: new FormControl('active'),
    product: new FormArray([
      new FormControl('')
    ])
  });

  form_type: string;
  id: string;
  promotion_obj: Promotion;
  product_id: number;
  products: Array<Select2OptionData> = [];
  options: any;
  apiUrl: string = environment.apiUrl;

  constructor(private promotionService: PromotionsService, private productService: ProductsService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.promotion_obj = new Promotion();
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'add'){
      this.form_type = 'Agregar';
    }else{
      this.form_type = 'Editar';
      this.promotionService.promotion_detail(this.id).subscribe( res =>{
        var products = res['product'];
        delete res['id'];
        this.promotion_obj = res;
        this.promotion_obj.product = [''];
        this.promotion_form.setValue(this.promotion_obj);
        this.promotion_obj.product = products;
        
      }, error => {
        this.router.navigateByUrl('/panel/promotions');
        this.toastr.error(error['error'].detail, "Error!");
      });
    }
    // Single datepicker
    $('.daterange-single').daterangepicker({ 
      singleDatePicker: true,
      locale: {
        "format": "YYYY-MM-DD",
        "applyLabel": "Aplicar",
        "cancelLabel": "Cancelar",
        "fromLabel": "DE",
        "toLabel": "HASTA",
        "customRangeLabel": "Custom",
        "daysOfWeek": [
            "Dom",
            "Lun",
            "Mar",
            "Mie",
            "Jue",
            "Vie",
            "Sáb"
        ],
        "monthNames": [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
      }
    });

    this.productService.products_promotion_list().subscribe( res =>{
      // Add products to an array for select2
      let product_list: Array<any> = [];
      product_list.push({id: '', text: 'Seleccione un producto...' });
      for( let i in res){
        product_list.push({id: res[i].id, text: `${res[i].code} - ${res[i].name}` });
      }
      this.products = product_list;
      $.unblockUI();
    });
    this.options = {
      placeholder: "Seleccione un producto...",
      width: "100%"
    }

  }

  add_product(){
    let products = (<FormArray>this.promotion_form.controls['product']);
    products.push( new FormControl('') );
  }

  remove_product(index) {
    let gallery = (<FormArray>this.promotion_form.controls['product']);
    if (gallery.length > 1){
      gallery.removeAt(index);
    }
  }

  set_product_id(id: number){
    this.product_id = id;
  }

  delete_product_promotion(){
    var product = {product_id: this.product_id}
    this.promotionService.remove_product_promotion(this.id, product).subscribe( res => {
      this.toastr.success("Producto eliminado correctamente.");
      this.promotion_obj.product.splice(this.promotion_obj.product.findIndex(p => p.id == this.product_id),1);
    });
  }

  save_promotion(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    var start_date = $('input.start_date').val();
    var finish_date = $('input.finish_date').val();

    this.promotion_form.get('start_date').setValue(start_date);
    this.promotion_form.get('finish_date').setValue(finish_date);
    
    if (this.form_type == 'Agregar') {
      this.promotionService.add_promotion(this.promotion_form.value).subscribe( res => {
        // Add product
        for( let product of this.promotion_form.get('product').value){
          if (product){
            this.promotionService.add_product_promotion(res['id'], {product_id: product}).subscribe();
          }
        }
        $.unblockUI();
        this.router.navigateByUrl('/panel/promotions');
        this.toastr.success("Promoción creada correctamente.");

      }, error => {
        $.unblockUI();
        console.log(error);
        this.toastr.warning(error['error'],"Error");
      });

    }else if(this.form_type == 'Editar'){
      this.promotionService.edit_promotion(this.id,this.promotion_form.value).subscribe( res => {
        // Add product
        for( let product of this.promotion_form.get('product').value){
          if (product){
            this.promotionService.add_product_promotion(this.id, {product_id: product}).subscribe();
          }
        }
        $.unblockUI();
        this.router.navigateByUrl('/panel/promotions');
        this.toastr.success("Promoción modificada correctamente.");
      }, error => {
        $.unblockUI();
        console.log(error);
        this.toastr.warning(error['error'],"Error");
      });
    }

    
  }

}
