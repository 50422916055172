import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ApplicantsService } from './../../services/applicants.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth.service';
import { Applicant } from './../../models/applicant';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';


@Component({
    selector: 'app-applicants-list',
    templateUrl: './applicants-list.component.html',
    styleUrls: ['./applicants-list.component.css']
})

export class ApplicantsListComponent implements OnInit {

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtTrigger = new Subject();
    
    applicants_list: any;
    applicant_detail: Applicant;
    apiUrl: string = environment.apiUrl;
    applicant_id: number;
    applicant_action: string;

    constructor(private http: HttpClient, private applicantService: ApplicantsService, private auth: AuthService, private router: Router, private toastr: ToastrService) {
        this.dtOptions = {
            "language": {
              "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
            }
        };
    }

    ngOnInit() {
        this.applicant_detail = new Applicant();
        this.get_applicants_list();
    }
    
    get_applicants_list(){
        this.applicantService.applicants_team_list().subscribe(res => {
            this.applicants_list = res;
            this.dtTrigger.next();
        }, error => {
            if(error['status'] == 401){
                this.router.navigateByUrl('/auth/panel_login');
                this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión");
                this.auth.clearToken();
            }else this.toastr.error("Error");
        });
    }

    set_applicant_id(id: number){
        this.applicantService.applicant_detail(id).subscribe(res => {
            this.applicant_detail = res;
        });
    }

    set_applicant_action(id: number, action: string){
        this.applicant_id = id;
        this.applicant_action = action;
    }

    proccess_applicant(){
        const data = {status_postulant: this.applicant_action}
        this.applicantService.applicant_update(this.applicant_id, data).subscribe(res =>{
            if(data.status_postulant == 'approved')
                this.toastr.success("El aplicante ha sido aprobado");
            else
                this.toastr.success("El aplicante ha sido rechazado");
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
            });
            this.get_applicants_list();
        }, error => {
            if(error['status'] == 401){
                this.router.navigateByUrl('/auth/panel_login');
                this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión");
                this.auth.clearToken();
            }else this.toastr.error("Error");
        });
    }

    exportExcel(){
        var today = new Date();
        var fileName = `ListaPostulantes_${today.getDate()}-${today.getMonth()+1}-${today.getFullYear()}.xlsx`;
        var jsonData = this.applicants_list.map( a => {
        var applicants = {};
        applicants['Nombre'] = a.first_name;
        applicants['Apellido'] = a.last_name;
        applicants['Correo'] = a.email;
        applicants['Teléfono'] = a.phone;
        applicants['Dirección'] = a.address;
        applicants['Estatus'] = (a.status_postulant == 'approved') ? 'Aprobada':(a.status_postulant == 'pending') ? 'Pendiente':(a.status_postulant == 'rejected') ? 'Rechazada': '';
        return applicants;
        });

        let title = 'Lista de Postulantes al Equipo';
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet(title);

        let titleRow = worksheet.addRow([title]);
        titleRow.font = { family: 4, size: 16, bold: true };
        worksheet.addRow([]);
        worksheet.addRow([]);
        let headerRow = worksheet.addRow(Object.keys(jsonData[0]));
        headerRow.font = { family: 4, size: 12, bold: true };

        // Get logo
        this.http.get('/assets/portal/img/Fleximatic-logo-01.png', { responseType: 'blob' }).subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = () => {
            var base64logo = reader.result.toString();
            var logo = workbook.addImage({
            base64: base64logo,
            extension: 'png',
            });
            worksheet.addImage(logo, 'F1:H3');
            // Download xlsx
            workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, `${fileName}.xlsx`);
            });
        }

        reader.readAsDataURL(res); 
        });
        
        worksheet.mergeCells('A1:D2');
        worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
        jsonData.forEach(e => {
        worksheet.addRow(Object.values(e));
        });
    }
}
