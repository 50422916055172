import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class EcommerceService {

    apiURLProducts: string = `${environment.apiUrl}/api/v1/products/`;
    apiURLCategories: string = `${environment.apiUrl}/api/v1/product_categories/`
    apiURLShopping: string = `${environment.apiUrl}/api/v1/shopping/`
    apiURLDynamicbutton: string = `${environment.apiUrl}/api/v1/english_section/`;

    private productsFilterMessage = new Subject<string>();
    private totalCart = new Subject<number>();
    productsFilterList: any;
    pagination: number;
    objectCount: number;

    constructor(private http: HttpClient) { }

    // Productos
    getAllProducts(){
        return this.http.get(`${this.apiURLProducts}ecommerce/all/`)
    }

    getAllProductsPaginated(page){
        return this.http.get(`${this.apiURLProducts}ecommerce/all/${page}`)
    }

    getNewestProducts(){
        return this.http.get(`${this.apiURLProducts}ecommerce/`);
    }

    getNewestProductsPaginated(page){
        return this.http.get(`${this.apiURLProducts}ecommerce/${page}`);
    }

    getProductsByCategory(id){
        return this.http.get(`${this.apiURLProducts}ecommerce/category/${id}`)
    }

    getProductsByCategoryPaginated(id, page){
        return this.http.get(`${this.apiURLProducts}ecommerce/category/${id}/${page}`);
    }

    getProductsByFilter(data){
        return this.http.get(`${this.apiURLProducts}ecommerce/search/${data}/`);
    }
    
    getProductsByFilterPaginated(data, page){
        return this.http.get(`${this.apiURLProducts}ecommerce/search/${data}/${page}`);
    }

    getProductDetail(id){
        return this.http.get(`${this.apiURLProducts}ecommerce/detail/${id}/`);
    }

    getProductsCategories(){
        return this.http.get(`${this.apiURLCategories}ecommerce/`);
    }

    getFilterMessage():Observable<string>{
        return this.productsFilterMessage.asObservable();
    }

    updateFilterMessage(message: string){
        this.productsFilterMessage.next(message);
    }

    getTotalCart(){
        return this.totalCart.asObservable();
    }

    updateTotalCart(total: number){
        this.totalCart.next(total);
    }

    checkoutProducts(body){
        return this.http.post(`${this.apiURLProducts}ecommerce/checkout/`, body);
    }

    checkoutOpenPay(body){
        return this.http.post(`${this.apiURLShopping}checkout/`, body);
    }

    getProductsCatalog(){
        return this.http.get(`${this.apiURLProducts}ecommerce/catalog/`);
    }
    
    getProductsCatalogPaginated(page){
        return this.http.get(`${this.apiURLProducts}ecommerce/catalog/${page}/`);
    }

    getProductsCatalogByCategory(id){
        return this.http.get(`${this.apiURLProducts}ecommerce/catalog/category/${id}/`);
    }

    getProductsCatalogByCategoryPaginated(id, page){
        return this.http.get(`${this.apiURLProducts}ecommerce/catalog/category/${id}/${page}/`);
    }

    getProductsCatalogByFilter(data){
        return this.http.get(`${this.apiURLProducts}ecommerce/catalog/search/${data}/`);
    }

    getProductsCatalogByFilterPaginated(data, page){
        return this.http.get(`${this.apiURLProducts}ecommerce/catalog/search/${data}/${page}/`);
    }

    getProductCatalogDetail(id){
        return this.http.get(`${this.apiURLProducts}ecommerce/catalog/detail/${id}/`);
    }

    downloadCatalog(id){
        return this.http.get(`${this.apiURLProducts}ecommerce/catalog/file/download/${id}/`);
    }

    botton_list(){
        return this.http.get(`${this.apiURLDynamicbutton}dynamic/client/`);
    }

}
