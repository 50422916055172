import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoriesService {

  apiURL: string = `${environment.apiUrl}/api/v1/product_categories/`;

  constructor(private http: HttpClient) { }

  product_categories_list(){
    return this.http.get(this.apiURL);
  }

  product_category_detail(id){
    return this.http.get(`${this.apiURL}${id}/`);
  }

  add_product_category(data){
    return this.http.post(`${this.apiURL}add/`, data);
  }

  edit_product_category(id, data){
    return this.http.put(`${this.apiURL}${id}/`, data);
  }

  delete_product_category(id){
    return this.http.delete(`${this.apiURL}${id}/`);
  }

}
