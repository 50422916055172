import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PromotionsService {

  apiURL: string = `${environment.apiUrl}/api/v1/promotions/`;

  constructor(private http: HttpClient) { }

  promotions_list(){
    return this.http.get(this.apiURL);
  }

  promotion_detail(id){
    return this.http.get<any>(`${this.apiURL}detail/${id}/`);
  }

  add_promotion(data){
    return this.http.post(`${this.apiURL}add/`, data);
  }

  edit_promotion(id, data){
    return this.http.put(`${this.apiURL}detail/${id}/`, data);
  }

  delete_promotion(id){
    return this.http.delete(`${this.apiURL}detail/${id}/`);
  }

  add_product_promotion(promotion_id, product_id){
    return this.http.post(`${this.apiURL}add_product/${promotion_id}/`,product_id);
  }

  remove_product_promotion(promotion_id, product_id){
    return this.http.put(`${this.apiURL}remove_product/${promotion_id}/`,product_id);
  }

}
