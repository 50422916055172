import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoriesService {

  apiURL: string = `${environment.apiUrl}/api/v1/inventories/`;

  constructor(private http: HttpClient) { }

  inventory_list(){
    return this.http.get(this.apiURL);
  }

  inventory_detail(id){
    return this.http.get(`${this.apiURL}detail/${id}/`);
  }

  product_list(){
    return this.http.get(`${this.apiURL}products/`);
  }

  add_inventory(data){
    return this.http.post(`${this.apiURL}add/`, data);
  }

  edit_inventory(id, data){
    return this.http.put(`${this.apiURL}detail/${id}/`, data);
  }

  delete_inventory(id){
    return this.http.delete(`${this.apiURL}${id}/`);
  }

}