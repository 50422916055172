import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PurchasesService } from '../../services/purchases.service';
import { AuthService } from '../../../auth/services/auth.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

declare var $: any;
@Component({
  selector: 'app-purchases-list',
  templateUrl: './purchases-list.component.html',
  styleUrls: ['./purchases-list.component.css']
})
export class PurchasesListComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtElement2: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();
  dtTrigger2 = new Subject();

  purchases_list: any;
  closed_purchases_list: any;

  constructor(private http: HttpClient, private purchaseService: PurchasesService, private auth: AuthService,  private router: Router, private toastr: ToastrService) {
    this.dtOptions = {
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      }
    };
  }

  ngOnInit() {
    this.get_purchases_list();
  }

  get_purchases_list(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.purchaseService.purchases_list().subscribe( res =>{
      this.purchases_list = res;
      this.dtTrigger.next();
      $.unblockUI();
      this.get_closed_purchases_list()
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
        $.unblockUI();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  get_closed_purchases_list(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.purchaseService.purchases_closed().subscribe( res =>{
      this.closed_purchases_list = res;
      this.dtTrigger2.next();
      $.unblockUI();
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
        $.unblockUI();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  exportExcel(){
    var today = new Date();
    var fileName = `ListaCompras_${today.getDate()}-${today.getMonth()+1}-${today.getFullYear()}.xlsx`;
    var jsonData: Array<any> = this.purchases_list.map( p => {
      var purchases = {};
      purchases['ID'] = p.id;
      purchases['Fecha'] = p.created_at;
      purchases['Subtotal'] = p.amount;
      purchases['IVA'] = p.tax;
      purchases['Total'] = p.total;
      purchases['Costo de envío'] = p.shipping_cost;
      purchases['Tipo'] = (p.client.is_distributor) ? "Distribuidor":"Cliente";
      purchases['Status'] = (p.purchase_status=='new') ? "Nueva":(p.purchase_status=='tracing') ? "Seguimiento":(p.purchase_status=='authorized') ? "Autorizada":(p.purchase_status=='sent') ? "Enviada":(p.purchase_status=='closed') ? "Cerrada":"";
      purchases['Número de rastreo'] = p.tracking_number;
      return purchases;
    });

    jsonData = jsonData.concat(this.closed_purchases_list.map( p => {
      var purchases = {};
      purchases['ID'] = p.id;
      purchases['Fecha'] = p.created_at;
      purchases['Subtotal'] = p.amount;
      purchases['IVA'] = p.tax;
      purchases['Total'] = p.total;
      purchases['Costo de envío'] = p.shipping_cost;
      purchases['Tipo'] = (p.client.is_distributor) ? "Distribuidor":"Cliente";
      purchases['Status'] = (p.purchase_status=='new') ? "Nueva":(p.purchase_status=='tracing') ? "Seguimiento":(p.purchase_status=='authorized') ? "Autorizada":(p.purchase_status=='sent') ? "Enviada":(p.purchase_status=='closed') ? "Cerrada":"";
      purchases['Número de rastreo'] = p.tracking_number;
      return purchases;
    }));
    jsonData.sort(this.GetSortOrder("ID"));

    let title = 'Lista de Compras';
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(title);

    let titleRow = worksheet.addRow([title]);
    titleRow.font = { family: 4, size: 16, bold: true };
    worksheet.addRow([]);
    worksheet.addRow([]);
    let headerRow = worksheet.addRow(Object.keys(jsonData[0]));
    headerRow.font = { family: 4, size: 12, bold: true };

    // Get logo
    this.http.get('/assets/portal/img/Fleximatic-logo-01.png', { responseType: 'blob' }).subscribe(res => {
      const reader = new FileReader();
      reader.onloadend = () => {
        var base64logo = reader.result.toString();
        var logo = workbook.addImage({
          base64: base64logo,
          extension: 'png',
        });
        worksheet.addImage(logo, 'F1:H3');
        // Download xlsx
        workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, `${fileName}.xlsx`);
        });
      }

      reader.readAsDataURL(res); 
    });
    
    worksheet.mergeCells('A1:D2');
    worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' };
    jsonData.forEach(e => {
      worksheet.addRow(Object.values(e));
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger2.unsubscribe();
  }

  //Comparer Function
  GetSortOrder(prop) {
    return function(a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }    
  }  

}
