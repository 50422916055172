import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CatalogService {

    apiUrlCategories: string = `${environment.apiUrl}/api/v1/product_categories/`;
    apiUrlProducts: string = `${environment.apiUrl}/api/v1/products/`;

    constructor(private http: HttpClient) { }

    getCategoriesList(){
        return this.http.get(`${this.apiUrlCategories}ecommerce/filter/`);
    }

    getFeaturedCategoriesList(){
        return this.http.get(`${this.apiUrlCategories}ecommerce/featured/`);
    }

    getAllCatalogProductsInfo(){
        return this.http.get(`${this.apiUrlProducts}ecommerce/catalog/`);
    }

    getAllCatalogProductsByPage(page){
        return this.http.get(`${this.apiUrlProducts}ecommerce/catalog/${page}/`);
    }

    getCategoryCatalogProductsInfo(category){
        return this.http.get(`${this.apiUrlProducts}ecommerce/catalog/category/${category}/`);
    }

    getCategoryCatalogProductByPage(category, page){
        return this.http.get(`${this.apiUrlProducts}ecommerce/catalog/category/${category}/${page}/`);
    }

    getCategoryCatalogProductSearchInfo(data){
        return this.http.get(`${this.apiUrlProducts}ecommerce/catalog/search/${data}/`);
    }

    getCategoryCatalogProductSearchByPage(data, page){
        return this.http.get(`${this.apiUrlProducts}ecommerce/catalog/search/${data}/${page}/`);
    }

    getCatalogProductDetail(id){
        return this.http.get(`${this.apiUrlProducts}ecommerce/catalog/detail/${id}/`);
    }
}
