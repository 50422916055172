import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styles: ['./panel.component.css']
})
export class PanelComponent implements OnInit {
  year: number = new Date().getFullYear();
  user: any = {first_name: '', last_name: ''};
  apiUrl: string = environment.apiUrl;
  
  constructor(private auth: AuthService, private router: Router) {  }

  ngOnInit() {
    if (this.auth.isAuth()){
      this.auth.user_profile().subscribe( res => {
        this.user = res;
      });
    }
  }

}
