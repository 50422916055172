import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { InvoicesService } from '../../services/invoices.service';
import { AuthService } from '../../../auth/services/auth.service';
import { environment } from '../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.css']
})
export class InvoicesListComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  invoices_list: any;
  invoice_id: number;
  sale_order_id: number;
  invoice_pdf: any;
  invoice_xml: any;
  apiUrl = environment.apiUrl;

  constructor(private invoiceService: InvoicesService, private auth: AuthService,  private router: Router, private toastr: ToastrService) {
    this.dtOptions = {
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      }
    };
  }

  ngOnInit() {
    this.get_invoices_list();
  }

  get_invoices_list(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.invoiceService.invoices_list().subscribe( res =>{
      this.invoices_list = res;
      this.dtTrigger.next();
      $.unblockUI();
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  set_invoice_id(id: number, sale_order: number){
    this.invoice_id = id;
    this.sale_order_id = sale_order;
  }

  delete_invoice(){
    this.invoiceService.delete_invoice(this.invoice_id).subscribe( res => {
      this.toastr.success("Factura eliminada correctamente.");
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.get_invoices_list();
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  save_invoice(){
    if(!this.invoice_pdf || !this.invoice_xml){
      this.toastr.warning("Seleccione los archivos de factura.");
      return;
    }
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });

    const data = {
      sale_order: this.sale_order_id,
      pdf_file: this.invoice_pdf,
      xml_file: this.invoice_xml
    };
    this.invoiceService.edit_invoice(this.invoice_id, data).subscribe(res =>{
      this.toastr.success("Factura modificada correctamente.");
      $("#invoice_form").trigger("reset");
      $("#modal_edit").modal('hide');
      $.unblockUI();
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.get_invoices_list();
    }, error =>{
      console.log(error);
      this.toastr.error('Error!')
      $("#invoice_form").trigger("reset");
      $("#modal_edit").modal('hide');
      $.unblockUI();
    })
  }

  onFilePDFChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if(!file){
      this.invoice_pdf = '';
      return;
    }
    var pattern = 'application/pdf';
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      return;
    }

    reader.onload = () => {
      this.invoice_pdf = reader.result;
    }
    reader.readAsDataURL(file);
  }

  onFileXMLChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if(!file){
      this.invoice_xml = '';
      return;
    }
    var pattern = 'text/xml';
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      return;
    }

    reader.onload = () => {
      this.invoice_xml = reader.result;
    }
    reader.readAsDataURL(file);
  }

}
