import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PurchasesService } from '../../services/purchases.service';
import { AuthService } from '../../../auth/services/auth.service';
import { DistributorsService } from '../../services/distributors.service';

declare var $: any;

@Component({
  selector: 'app-distributor-purchase-history',
  templateUrl: './distributor-purchase-history.component.html',
  styleUrls: ['./distributor-purchase-history.component.css']
})
export class DistributorPurchaseHistoryComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  distributor_id: any;
  distributor_obj: any = {distributor: {}};
  purchases_list: any;

  constructor(private purchaseService: PurchasesService, private distributorService: DistributorsService, private auth: AuthService,  private router: Router, private route: ActivatedRoute, private toastr: ToastrService) {
    this.dtOptions = {
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      }
    };
  }

  ngOnInit() {
    this.distributor_id = this.route.snapshot.paramMap.get('id');
    this.get_distributor_detail();
  }

  get_distributor_detail(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.distributorService.distributor_detail(this.distributor_id).subscribe( res => {
      this.distributor_obj = res;
      this.get_distributor_purchase_history();
    }, error => {
      if (error['status'] == 401){
        $.unblockUI();
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
      }else{
        this.toastr.error("Error!");
        this.router.navigateByUrl('/panel/distributors');
        $.unblockUI();
      }
    });
  }

  get_distributor_purchase_history(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.purchaseService.distributor_purchase_history(this.distributor_id).subscribe( res =>{
      this.purchases_list = res;
      this.dtTrigger.next();
      $.unblockUI();
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
