import { environment } from './../../../../environments/environment';
import { EcommerceService } from './../../services/ecommerce.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PersistenceService, StorageType } from 'angular-persistence';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

    constructor(private router: Router, private persistenceService: PersistenceService, private ecommerceService: EcommerceService, private authService: AuthService) { }

    @ViewChild('sweetAlertAuth', {static: false}) private sweetAlertAuth: SwalComponent;

    cart: any;
    cartDetail: any;
    apiURL: string = environment.apiUrl;
    totalCart: any;
    isDistributor: boolean;

    ngOnInit() {
        if(localStorage.getItem('distributor') == 'false' || !localStorage.getItem('distributor')){
            this.isDistributor = false;
        }else{
            this.isDistributor = true;
        }
        this.cart = this.persistenceService.get('cart', StorageType.SESSION);
        this.totalCart = this.persistenceService.get('totalCart', StorageType.SESSION);
        this.cartDetail = undefined;
        if(this.cart != undefined){
            this.getAllProducts();
        }
    }


    getAllProducts(){
        this.cartDetail = [];
        for(let item of this.cart['products_list']){
            this.ecommerceService.getProductDetail(item['id']).subscribe(res => {
                if(res['product']['discount'].length == 0){
                    if(this.isDistributor){
                        this.cartDetail.push({
                            "id": res['product'].id,
                            "img": res['product'].image,
                            "name": res['product'].name,
                            "price": res['product'].distributor_price,
                            "qty": item['quantity'],
                            "sub": ((res['product'].distributor_price * item['quantity'])/1.16).toFixed(2),
                            "iva": (((res['product'].distributor_price * item['quantity'])/1.16)*0.16).toFixed(2),
                            "total": (res['product'].distributor_price * item['quantity']).toFixed(2),
                            "max_purchase": res['product'].max_purchase,
                            "stock": res['stock'],
                        });
                    }else{
                        this.cartDetail.push({
                            "id": res['product'].id,
                            "img": res['product'].image,
                            "name": res['product'].name,
                            "price": res['product'].customer_price,
                            "qty": item['quantity'],
                            "sub": ((res['product'].customer_price * item['quantity'])/1.16).toFixed(2),
                            "iva": (((res['product'].customer_price * item['quantity'])/1.16)*0.16).toFixed(2),
                            "total": (res['product'].customer_price * item['quantity']).toFixed(2),
                            "max_purchase": res['product'].max_purchase,
                            "stock": res['stock'],
                        });
                    }
                }else{
                    if(this.isDistributor){
                        this.cartDetail.push({
                            "id": res['product'].id,
                            "img": res['product'].image,
                            "name": res['product'].name,
                            "price": (res['product'].distributor_price - (res['product'].distributor_price * (res['product']['discount'][0]['discount_rate']/100 ))),
                            "qty": item['quantity'],
                            "sub": (((res['product'].distributor_price - (res['product'].distributor_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity'])/1.16).toFixed(2),
                            "iva": ((((res['product'].distributor_price - (res['product'].distributor_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity'])/1.16)*0.16).toFixed(2),
                            "total": ((res['product'].distributor_price - (res['product'].distributor_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity']).toFixed(2),
                            "max_purchase": res['product'].max_purchase,
                            "stock": res['stock'],
                        });
                    }else{
                        this.cartDetail.push({
                            "id": res['product'].id,
                            "img": res['product'].image,
                            "name": res['product'].name,
                            "price": (res['product'].customer_price - (res['product'].customer_price * (res['product']['discount'][0]['discount_rate']/100 ))),
                            "qty": item['quantity'],
                            "sub": (((res['product'].customer_price - (res['product'].customer_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity'])/1.16).toFixed(2),
                            "iva": ((((res['product'].customer_price - (res['product'].customer_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity'])/1.16)*0.16).toFixed(2),
                            "total": ((res['product'].customer_price - (res['product'].customer_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity']).toFixed(2),
                            "max_purchase": res['product'].max_purchase,
                            "stock": res['stock'],
                        });
                    }
                    
                }

                
            });
        }
    }

    onChangeQty(id: number, qty: string){
        var max_purchase;
        var val = qty;
        this.cart['products_list'].find((o, i)=>{
            if(o.id === id){
                this.cartDetail.find((o, i)=>{
                    if(o.id === id){
                        max_purchase =  o.max_purchase;
                        if(parseInt(val) < 1){
                            val = '1';
                        }
                        if(val > max_purchase){
                            val = max_purchase;
                        }
                        this.cartDetail[i] = {
                            "id": o.id,
                            "img": o.img,
                            "name": o.name,
                            "price": o.price,
                            "qty": parseInt(val),
                            "sub": ((o.price * parseFloat(val))/1.16).toFixed(2),
                            "iva": (((o.price * parseFloat(val))/1.16)*0.16).toFixed(2),
                            "total": (o.price * parseFloat(val)).toFixed(2),
                            "max_purchase": o.max_purchase,
                        }
                    }
                });
                this.cart['products_list'][i] = {id: id, quantity: parseInt(val)};
                this.persistenceService.set('cart', this.cart, {type: StorageType.SESSION});
                return true;
            }
        });
        this.changeCartTotal();
    }

    deleteFromCart(id: number, index: number){
        var spliceIndex;
        this.cart['products_list'].find((o, i) => {
            if(o.id === id){
                spliceIndex = i;
                return true;
            }
        });
        this.cartDetail.splice([index], 1);
        this.cart['products_list'].splice([spliceIndex], 1);
        this.persistenceService.set('cart', this.cart, {type: StorageType.SESSION});
        this.changeCartTotal();
    }

    changeCartTotal(){
        this.totalCart = 0;
        if(this.cartDetail.length == 0){
            this.ecommerceService.updateTotalCart(this.totalCart);
            this.cartDetail = null;
        }else{
            let totalNumber: number = 0;
            for(let item of this.cartDetail){
                totalNumber += +item.total;
                this.ecommerceService.updateTotalCart(totalNumber);
                this.totalCart = totalNumber.toFixed(2);
            }
        }
    }

    checkout(){
        if(this.authService.isAuth()){
            if (!localStorage.getItem('admin')) {
                this.router.navigateByUrl("/ecommerce/checkout");
            }else{
                this.authService.logout().subscribe(res => {
                    this.sweetAlertAuth.fire();
                });
            }
        }else{
            this.sweetAlertAuth.fire();
        }
    }

}
