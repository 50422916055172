import { ContactService } from './../../services/contact.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

    contactForm = new FormGroup({
        first_name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        city: new FormControl(''),
        phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
        email: new FormControl('', [Validators.required, Validators.email]),
        message: new FormControl('', [Validators.required]),
    });

    constructor(private router: Router, private toastr: ToastrService, private contactService: ContactService) { }

    ngOnInit() { }

    sendEmail() {
        const formData = new FormData();
        formData.append("nombre_contactanos", this.contactForm.get('first_name').value);
        formData.append("apellido_contactanos", this.contactForm.get('last_name').value);
        formData.append("ciudad_contactanos", this.contactForm.get('city').value);
        formData.append("telefono_contactanos", this.contactForm.get('phone').value);
        formData.append("correo_contactanos", this.contactForm.get('email').value);
        formData.append("descripcion_contactanos", this.contactForm.get('message').value);

        this.contactService.sendEmail(formData).subscribe(res => {
            console.log(res);
            this.toastr.success("Enviado", "Se ha enviado un correo, alguien se pondrá en contacto pronto")
        },error => {
            console.log(error.error);
            this.toastr.error("Error", "Ha ocurrido un error");
        })
        
    }

    validateForm(){
        var dict = {
            first_name: "nombre",
            last_name: "apellido",
            phone: "teléfono",
            email: "correo",
            message: "asunto",
        }
        if(!this.contactForm.valid){
            Object.keys(this.contactForm.controls).forEach(key => {
                const controlErrors: ValidationErrors = this.contactForm.get(key).errors;
                if (controlErrors != null) {
                    Object.keys(controlErrors).forEach(keyError => {
                        this.toastr.error("El campo "+dict[key]+" es requerido");
                    });
                }
            });
        }
    }

}
