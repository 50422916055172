import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ShippingMethodsService } from '../../services/shipping-methods.service';
import { ToastrService } from 'ngx-toastr';

declare var $: any;
@Component({
  selector: 'app-shipping-methods-form',
  templateUrl: './shipping-methods-form.component.html',
  styleUrls: ['./shipping-methods-form.component.css']
})
export class ShippingMethodsFormComponent implements OnInit {

  shipping_method_form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    provider: new FormControl('', Validators.required),
    min_days: new FormControl('', [Validators.required, Validators.min(1)]),
    max_days: new FormControl('', [Validators.required, Validators.min(1)]),
    is_active: new FormControl('true', Validators.required)
  });
  prices_weights: FormArray = new FormArray([ 
    new FormGroup({
      price : new FormControl('',[Validators.required, Validators.min(1)]),
      weight: new FormControl('',[Validators.required, Validators.min(1)])
    })
  ]);
  shipping_method_obj: any = {};
  price_weight_id: number;
  form_type: string;
  id: string;

  constructor(private shippingMethod: ShippingMethodsService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'add'){
      this.form_type = 'Agregar';
    }else{
      this.form_type = 'Editar';
      $.blockUI({ 
        message: '<i class="icon-spinner4 spinner"></i>',
        overlayCSS: {
            backgroundColor: '#1b2024',
            opacity: 0.8,
            zIndex: 1200,
            cursor: 'wait'
        },
        css: {
            border: 0,
            color: '#fff',
            padding: 0,
            zIndex: 1201,
            backgroundColor: 'transparent'
        }
      });
      this.shippingMethod.shipping_method_detail(this.id).subscribe( res =>{
        var prices_weight = res['price_by_weight_shipping']
        delete res['id'];
        delete res['price_by_weight_shipping']
        this.shipping_method_obj = res;
        this.shipping_method_form.setValue(this.shipping_method_obj);
        this.shipping_method_obj.price_by_weight_shipping = prices_weight;
        $.unblockUI();
      }, error => {
        this.router.navigateByUrl('/panel/shipping_methods');
        this.toastr.error(error['error'].detail, "Error!");
        $.unblockUI();
      });
    }
  }

  add_price_weight(){
    this.prices_weights.push( 
      new FormGroup({
        price : new FormControl('',[Validators.required, Validators.min(1)]),
        weight: new FormControl('',[Validators.required, Validators.min(1)])
      })
    );
  }

  remove_price_weight(index) {
    if (this.prices_weights.length > 1){
      this.prices_weights.removeAt(index);
    }
  }

  set_price_weight_id(id: number){
    this.price_weight_id = id;
  }

  delete_price_weight(){
    this.shippingMethod.delete_price_by_weight(this.price_weight_id).subscribe( res => {
      this.toastr.success("Precio por peso eliminado correctamente.");
      this.shipping_method_obj.price_by_weight_shipping.splice(this.shipping_method_obj.price_by_weight_shipping.findIndex(p => p.id == this.price_weight_id),1);
    });
  }

  save_shipping_method(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });

    if (this.form_type == 'Agregar') {

      this.shippingMethod.add_shipping_method(this.shipping_method_form.value).subscribe( res => {
        // Add Price by Weight
        for( let price_weight of this.prices_weights.value){
          if (price_weight.price && price_weight.weight){
            price_weight.shipping_method = res['id'];
            this.shippingMethod.add_price_by_weight(price_weight).subscribe();
          }
        }
        $.unblockUI();
        this.router.navigateByUrl('/panel/shipping_methods');
        this.toastr.success("Método de envío creado correctamente.");
      }, error => {
        this.toastr.error("Error!");
        $.unblockUI();
      });

    }else if(this.form_type == 'Editar'){
      this.shippingMethod.edit_shipping_method(this.id,this.shipping_method_form.value).subscribe( res => {
        // Add Price by Weight
        for( let price_weight of this.prices_weights.value){
          if (price_weight.price && price_weight.weight){
            price_weight.shipping_method = this.id;
            this.shippingMethod.add_price_by_weight(price_weight).subscribe();
          }
        }
        $.unblockUI();
        this.router.navigateByUrl('/panel/shipping_methods');
        this.toastr.success("Método de envío modificado correctamente.");
      }, error => {
        this.toastr.error("Error!");
        $.unblockUI();
      });
    }
    
  }

}
