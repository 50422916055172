import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PanelGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router){}

  canActivate():boolean {
    if (this.auth.isAuth()){
      if(localStorage.getItem('admin')){
        return true;
      }else{
        this.router.navigateByUrl('/');
        return false;
      }
      
    }else{
      this.router.navigateByUrl('/auth/panel_login');
      return false;
    }
  }
  
}
