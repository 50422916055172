import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PromotionsService } from '../../services/promotions.service';
import { AuthService } from '../../../auth/services/auth.service';

declare var $: any;

@Component({
  selector: 'app-promotions-list',
  templateUrl: './promotions-list.component.html',
  styleUrls: ['./promotions-list.component.css']
})
export class PromotionsListComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  promotion_form = new FormGroup({
    name: new FormControl({value: '', disabled: true}, [Validators.required]),
    start_date: new FormControl(''),
    finish_date: new FormControl(''),
    discount_rate: new FormControl({value: '', disabled: true}),
    description: new FormControl({value: '', disabled: true}),
    status_promotion: new FormControl('active')
  });

  promotions_list: any;
  promotion_id: number;
  promotion_obj: any;

  constructor(private promotionService: PromotionsService, private auth: AuthService,  private router: Router, private toastr: ToastrService) {
    this.dtOptions = {
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      }
    };
  }

  ngOnInit() {
    this.get_promotions_list();
  }

  get_promotions_list(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.promotionService.promotions_list().subscribe( res =>{
      this.promotions_list = res;
      this.dtTrigger.next();
      $.unblockUI();
    }, error => {
      if (error['status'] == 401){
        this.router.navigateByUrl('/auth/panel_login');
        this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
        this.auth.clearToken();
      }else{
        this.toastr.error("Error!");
        $.unblockUI();
      }
    });
  }

  set_promotion_id(id: number){
    this.promotion_id = id;
  }

  delete_promotion(){
    this.promotionService.delete_promotion(this.promotion_id).subscribe( res => {
      this.toastr.success("Promoción eliminada correctamente.");
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.get_promotions_list();
    });
  }

  cancel_promotion(){
    this.promotionService.promotion_detail(this.promotion_id).subscribe(res => {
      res['status_promotion'] = 'canceled';
      this.promotionService.edit_promotion(this.promotion_id, res).subscribe(res => {
        this.toastr.success("Promoción cancelada correctamente.");
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
        });
        this.get_promotions_list();
      });
    });
  }

  get_promotion_detail(id: number){
    // Single datepicker
    $('.daterange-single').daterangepicker({ 
      singleDatePicker: true,
      locale: {
        "format": "YYYY-MM-DD",
        "applyLabel": "Aplicar",
        "cancelLabel": "Cancelar",
        "fromLabel": "DE",
        "toLabel": "HASTA",
        "customRangeLabel": "Custom",
        "daysOfWeek": [
            "Dom",
            "Lun",
            "Mar",
            "Mie",
            "Jue",
            "Vie",
            "Sáb"
        ],
        "monthNames": [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
        ],
      }
    });

    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.promotion_form.reset();
    this.promotion_form.get('status_promotion').setValue('active');

    this.promotionService.promotion_detail(id).subscribe( res => {
      this.promotion_obj = res;
      this.promotion_form.get('name').setValue(res['name']);
      this.promotion_form.get('discount_rate').setValue(res['discount_rate']);
      this.promotion_form.get('description').setValue(res['description']);

      $.unblockUI();
    }, error => console.log(error));
  }

  save_promotion(){
    var start_date = $('input.start_date').val();
    var finish_date = $('input.finish_date').val();

    this.promotion_obj.start_date = start_date;
    this.promotion_obj.finish_date = finish_date;
    this.promotion_obj.status_promotion = 'active';

    this.promotionService.edit_promotion(this.promotion_obj.id, this.promotion_obj).subscribe(res => {
      this.toastr.success("Promoción reactivada correctamente.");
      $('#modal_reactive').modal('hide');
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
      this.get_promotions_list();
    }, error => {
      console.log(error);
      this.toastr.warning(error['error'],"Error");
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
