import { Component, OnInit } from '@angular/core';
import { CatalogService } from '../../services/catalog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-catalog',
    templateUrl: './catalog.component.html',
    styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements OnInit {

    type: string;
    page: number;
    category: string;
    categoriesList: any;
    productsList: any;
    pagination: number;
    categoryTitle: string;
    paginationHidden: boolean = false;


    searchForm = new FormGroup({
        search: new FormControl(''),
    });

    apiUrl: string = environment.apiUrl;

    private sub: any;

    constructor(private router: Router, private catalogService: CatalogService, private route: ActivatedRoute) { }

    ngOnInit() {
        this.sub = this.route.params.subscribe(() => {
            this.paginationHidden = false;
            this.type = this.route.snapshot.paramMap.get("type");
            this.page = parseInt(this.route.snapshot.paramMap.get("page"));
            this.category = this.route.snapshot.paramMap.get("category");
    
            if(this.type === 'categories'){
                this.getCategories();
            } else if(this.type === 'all'){
                this.getAllCatalogProducts(this.page);
            } else if(this.type === 'category'){
                this.getCategoryCatalogProducts(this.category, this.page);
            } else if (this.type === 'search'){
                this.getProductsBySearch(this.category, this.page);
            }
        })
        
    }

    getCategories(){
        this.catalogService.getCategoriesList().subscribe(res => {
            this.categoriesList = res;
        });
    }

    getAllCatalogProducts(page){
        this.catalogService.getAllCatalogProductsInfo().subscribe();
        this.catalogService.getAllCatalogProductsByPage(page).subscribe();
    }

    getCategoryCatalogProducts(category, page){
        this.catalogService.getCategoryCatalogProductsInfo(category).subscribe(res => {
            this.pagination = res['pages']

        });
        this.catalogService.getCategoryCatalogProductByPage(category, page).subscribe(res => {
            this.productsList = res;
            if(this.productsList.length == 0){
                this.router.navigateByUrl('/catalog/categories');
            }else{
                this.categoryTitle = res[0].category.name;
            }
        });
    }

    getProductsBySearch(search, page){
        this.catalogService.getCategoryCatalogProductSearchInfo(search).subscribe(res => {
            this.pagination = res['pages']
            if(res['products'] == 0){
                this.paginationHidden = true;
            }
        });
        this.catalogService.getCategoryCatalogProductSearchByPage(search, page).subscribe(res => {
            this.productsList = res;
        });
    }

    filterProducts() {
        var search = this.searchForm.get('search').value;
        if(search === ''){
            return;
        }
        this.getProductsBySearch(search, 1);
        this.searchForm = new FormGroup({
            search: new FormControl(''),
        });
        
        this.router.navigateByUrl(`/catalog/search/${search}/1`);
    }

}
