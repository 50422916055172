export class LandingPage {
    id: number;
    name: string;
    url: string;
    main_text: string;
    main_image: string;
    intermediate_text: string;
    intermediate_image: string;
    is_active: boolean;
    is_English:boolean;
    sections: any = [];
}