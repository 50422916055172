import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LandingPagesService {

  apiURL: string = `${environment.apiUrl}/api/v1/landing_pages/`;

  constructor(private http: HttpClient) { }


  landing_page_list_spanish(){
    return this.http.get(`${this.apiURL}list/spanish/`);
  }
  landing_page_list_english(){
    return this.http.get(`${this.apiURL}list/english/`);
  }

  landing_page_detail(id){
    return this.http.get<any>(`${this.apiURL}${id}/`);
  }

  add_landing_page(data){
    return this.http.post(`${this.apiURL}add/`, data);
  }

  edit_landing_page(id, data){
    return this.http.put(`${this.apiURL}${id}/`, data);
  }

  delete_landing_page(id){
    return this.http.delete(`${this.apiURL}${id}/`);
  }

  landing_page_by_url(url){
    return this.http.get<any>(`${this.apiURL}url/${url}/`);
  }

  add_landing_page_section(data){
    return this.http.post(`${this.apiURL}section/add/`,data);
  }

  edit_landing_page_section(id, data){
    return this.http.put(`${this.apiURL}section/${id}/`,data);
  }

  delete_landing_page_section(landing_page_section_id){
    return this.http.delete(`${this.apiURL}section/${landing_page_section_id}/`);
  }
}
