import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import {DynamicButonService} from '../../services/dynamic-buton.service';

declare var $: any;

@Component({
  selector: 'sidebar-dynamic-buton',
  templateUrl: './sidebar-dynamic-buton.component.html',
  styleUrls: ['./sidebar-dynamic-buton.component.css']
})
export class SidebarDynamicButonComponent implements OnInit {

@ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger = new Subject();

  name_id: number;
  nameList: any;

  constructor( private Dynamicbuton: DynamicButonService ,private router: Router, private auth: AuthService, private toastr: ToastrService) {
    this.dtOptions = {
      "language": {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json",
      },
      "order": [[0, "desc"]],
    };
  }

  ngOnInit() {
    this.getname();
}

getname() {
    $.blockUI({
        message: '<i class="icon-spinner4 spinner"></i>',
        overlayCSS: {
            backgroundColor: '#1b2024',
            opacity: 0.8,
            zIndex: 1200,
            cursor: 'wait'
        },
        css: {
            border: 0,
            color: '#fff',
            padding: 0,
            zIndex: 1201,
            backgroundColor: 'transparent'
        }
    });
    this.Dynamicbuton.botton_list().subscribe(res => {
        this.nameList = res;
        this.dtTrigger.next();
        $.unblockUI();
    }, error => {
        if (error['status'] == 401) {
            this.router.navigateByUrl('/auth/panel_login');
            this.toastr.warning("Credenciales inválidas, vuelva iniciar sesión.");
            this.auth.clearToken();
        } else {
            this.toastr.error("Error!");
            $.unblockUI();
        }
    })
}

set_name_id(id: number) {
    this.name_id = id;
}

delete_botton() {
    this.Dynamicbuton.delete_name_botton(this.name_id).subscribe(res => {
        this.toastr.success("Registro eliminado correctamente.");
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
        });
        this.getname();
    });
}

ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
}


}
