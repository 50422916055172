import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApplicantsService } from './../../../panel/services/applicants.service';

declare var $: any;

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

    slides = [
        { img: "assets/portal/img/Equipo/equipo-s1.png" },
        { img: "assets/portal/img/Equipo/equipo-s5.jpg" },
        { img: "assets/portal/img/Equipo/equipo-s3.png" },
        { img: "assets/portal/img/Equipo/equipo-s4.png" },
    ]
    slideConfig = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        dots: true,
        autoplaySpeed: 5000,
        arrows: false,
        fade: true,
        fadeSpeed: 1000
    }

    postulantForm = new FormGroup({
        first_name: new FormControl('', [Validators.required]),
        last_name: new FormControl('', [Validators.required]),
        phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
        email: new FormControl('', [Validators.required, Validators.email]),
        address: new FormControl('', [Validators.required]),
        curriculum: new FormControl('', [Validators.required]),
    });
    
    base64File: string;

    constructor(private router: Router, private toastr: ToastrService, private applicantsService: ApplicantsService) { }

    ngOnInit() {
        $("#upload").change(function () {
            var fichero_seleccionado = $(this).val();
            var nombre_fichero_seleccionado = fichero_seleccionado.replace(/.*[\/\\]/, ''); //Eliminamos el path hasta el fichero seleccionado
            $("#fichero_seleccionado").text(fichero_seleccionado);
            $("#nombrearchivo").val(nombre_fichero_seleccionado);
        });
    }

    saveApplicant() {
        const formData = new FormData();
        formData.append("first_name", this.postulantForm.get('first_name').value);
        formData.append("last_name", this.postulantForm.get('last_name').value);
        formData.append("phone", this.postulantForm.get('phone').value);
        formData.append("email", this.postulantForm.get('email').value);
        formData.append("address", this.postulantForm.get('address').value);
        formData.append("curriculum", this.base64File);

        this.applicantsService.applicant_add(formData).subscribe(res => {
            location.reload();
            this.toastr.success("Enviado!", "La información ha sido enviada correctamente");
            
        }, error => {
            this.toastr.error("Error", "Ha ocurrido un error");
            console.log(error);
        });
    }

    onFileChange(e) {
        var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        var pattern = /pdf-*/;
        var reader = new FileReader();
        if (!file.type.match(pattern)) {
          this.toastr.warning("Formato inválido.");
          return;
        }
    
        reader.onload = () => {
            this.base64File = reader.result as string;
        }
        reader.readAsDataURL(file);
    }

    validateForm(){
        var dict = {
            first_name: "nombre",
            last_name: "apellido",
            phone: "teléfono",
            email: "correo",
            address: "dirección",
            curriculum: "curriculum",
        }
        if(!this.postulantForm.valid){
            Object.keys(this.postulantForm.controls).forEach(key => {
                const controlErrors: ValidationErrors = this.postulantForm.get(key).errors;
                if (controlErrors != null) {
                    Object.keys(controlErrors).forEach(keyError => {
                        this.toastr.error("El campo "+dict[key]+" es requerido");
                    });
                }
            });
        }
    }
}
