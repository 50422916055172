import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from './../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { EcommerceService } from '../../services/ecommerce.service';
import { CatalogService } from '../../services/catalog.service';

@Component({
    selector: 'app-product-search',
    templateUrl: './product-search.component.html',
    styleUrls: ['./product-search.component.css']
})
export class ProductSearchComponent implements OnInit, OnDestroy {

    apiUrl: string = environment.apiUrl;
    title: string;
    search: string;
    productsPage: number;
    catalogPage: number;
    isDistributor: boolean;
    productsCount: number;
    catalogCount: number;
    productsList: any;
    catalogList: any;
    productsPagination: number;
    catalogPagination: number;
    subscription: any;

    private sub: any;

    constructor(private router: Router, private ecommerceService: EcommerceService, private catalogService: CatalogService, private route: ActivatedRoute) {
        this.subscription = this.ecommerceService.getFilterMessage().subscribe(message => {
            this.title = "Búsqueda de "+message;
            this.getProductsByFilter();
        });
    }

    ngOnInit() {
        if(localStorage.getItem('distributor') == 'false' || !localStorage.getItem('distributor')){
            this.isDistributor = false;
        }else{
            this.isDistributor = true;
        }

        this.sub = this.route.params.subscribe( () => {
            this.search = this.route.snapshot.paramMap.get("search");
            this.catalogPage = parseInt(this.route.snapshot.paramMap.get("page1"));
            this.productsPage = parseInt(this.route.snapshot.paramMap.get("page2"));

            this.getProductsByFilterByGet(this.search, this.productsPage);
            this.getCatalogBySearch(this.search, this.catalogPage);
        });

    }

    getProductsByFilterByGet(search: string, page: number){
        this.ecommerceService.getProductsByFilter(search).subscribe(res => {
            this.productsPagination = res['pages'];
            this.productsCount = res['products'];
        });
        this.ecommerceService.getProductsByFilterPaginated(search, page).subscribe(res => {
            this.productsList = res;
            this.title = "Búsqueda de "+search;
        });
    }

    getProductsByFilter(){
        this.productsList = this.ecommerceService.productsFilterList;
        this.productsPage = 1;
        this.productsPagination = this.ecommerceService.pagination;
        this.productsCount = this.ecommerceService.objectCount;
    }

    getCatalogBySearch(search, page){
        this.catalogService.getCategoryCatalogProductSearchInfo(search).subscribe(res => {
            this.catalogPagination = res['pages']
            this.catalogCount = res['products'];
        });
        this.catalogService.getCategoryCatalogProductSearchByPage(search, page).subscribe(res => {
            this.catalogList = res;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.sub.unsubscribe();
    }

}
