import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,  Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ProductsService } from '../../services/products.service';
import { ProductCategoriesService } from '../../services/product-categories.service';
import { BrandsService } from '../../services/brands.service';
import { environment } from '../../../../environments/environment';

declare var $: any;
@Component({
  selector: 'app-products-form',
  templateUrl: './products-form.component.html',
  styleUrls: ['./products-form.component.css']
})
export class ProductsFormComponent implements OnInit {

  product_form: FormGroup = new FormGroup({
    code: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
    category: new FormControl('', [Validators.required]),
    brand: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    data_sheet: new FormControl(''),
    customer_price: new FormControl('', [Validators.required, Validators.min(1)]),
    distributor_price: new FormControl('', [Validators.required, Validators.min(1)]),
    weight: new FormControl('', [Validators.required, Validators.min(0.1)]),
    max_purchase: new FormControl('', [Validators.required, Validators.min(1)]),
    is_new: new FormControl(false, [Validators.required]),
    is_catalog: new FormControl(false, Validators.required),
    image: new FormControl(''),
    image_thumbnail: new FormControl(''),
    is_active: new FormControl('true', Validators.required),
    gallery: new FormArray([
      new FormControl('')
    ])
  });

  form_type: string;
  id: string;
  product_obj: any;
  gallery_id:number;
  gallery_images: any = '';
  product_categories: any;
  brands: any;
  apiUrl: string = environment.apiUrl;

  constructor(private product: ProductsService, private product_category: ProductCategoriesService, private brand: BrandsService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) {  }

  ngOnInit() {
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      },
      
    });
    // Get product categories
    this.product_category.product_categories_list().subscribe( res =>{
      this.product_categories = res;
      $.unblockUI();
    });
    // Get brands
    this.brand.brands_list().subscribe( res =>{
      this.brands = res;
      $.unblockUI();
    });

    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'add'){
      this.form_type = 'Agregar';
      this.product_form.get('image').setValidators(Validators.required);
    }else{
      this.form_type = 'Editar';
      this.product.product_detail(this.id).subscribe( res =>{
        delete res['id'];
        delete res['discount'];
        this.product_obj = res;
        this.product_obj.category = res['category'].id;
        this.product_obj.brand = res['brand'].id;
        this.product_obj.gallery = [''];
        this.product_form.setValue(this.product_obj);
        this.product_form.get('data_sheet').setValue('');
        this.product_form.get('image').setValue('');
        this.product_form.get('image_thumbnail').setValue('');
        this.product_obj.gallery = [];

        $.blockUI({ 
          message: '<i class="icon-spinner4 spinner"></i>',
          overlayCSS: {
              backgroundColor: '#1b2024',
              opacity: 0.8,
              zIndex: 1200,
              cursor: 'wait'
          },
          css: {
              border: 0,
              color: '#fff',
              padding: 0,
              zIndex: 1201,
              backgroundColor: 'transparent'
          }
        });
        // Get gallery list
        this.product.product_gallery_list(this.id).subscribe(res => {
          this.gallery_images = res;
          $.unblockUI();
          // If gallery has 3 images remove gallery inputs
          if (this.gallery_images.length >= 3){
            (<FormArray>this.product_form.controls['gallery']).clear();
          }
        });
        
      }, error => {
        this.router.navigateByUrl('/panel/products');
        this.toastr.error(error['error'].detail, "Error!");
        $.unblockUI();
      });
    }

  }

  onImageChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      this.product_form.get('image').setValue('');
      this.product_form.get('image_thumbnail').setValue('');
      return;
    }

    reader.onload = () => {
      this.product_form.get('image').setValue(reader.result as string);
      this.product_form.get('image_thumbnail').setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  onGalleryChange(e,index) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      return;
    }

    reader.onload = () => {
      (<FormArray>this.product_form.get('gallery')).at(index).setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  onFilePDFChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if(!file){
      this.product_form.get('data_sheet').setValue('');
      return;
    }
    var pattern = 'application/pdf';
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      this.product_form.get('data_sheet').setValue('');
      return;
    }

    reader.onload = () => {
      this.product_form.get('data_sheet').setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  add_gallery_image(){
    let gallery = (<FormArray>this.product_form.controls['gallery']);

    if (gallery.length + this.gallery_images.length < 3){
      gallery.push( new FormControl() );
    }else
      this.toastr.warning("Máximo 3 imagenes para galería.")
  }

  remove_gallery_image(index) {
    let gallery = (<FormArray>this.product_form.controls['gallery']);
    if (gallery.length > 1){
      gallery.removeAt(index);
    }
  }

  set_gallery_id(id: number){
    this.gallery_id = id;
  }

  delete_product_gallery(){
    this.product.delete_product_gallery(this.gallery_id).subscribe( res => {
      this.toastr.success("Imagen de galería eliminada correctamente.");
      this.gallery_images.splice(this.gallery_images.findIndex(g => g.id == this.gallery_id),1);
      if(this.gallery_images.length == 2){
        this.add_gallery_image();
      }
    });
  }

  save_product(){
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    const formData = new FormData();
    formData.append("code", this.product_form.get('code').value);
    formData.append("name", this.product_form.get('name').value);
    formData.append("category", this.product_form.get('category').value);
    formData.append("brand", this.product_form.get('brand').value);
    formData.append("description", this.product_form.get('description').value);
    formData.append("data_sheet", this.product_form.get('data_sheet').value);
    formData.append("customer_price", this.product_form.get('customer_price').value);
    formData.append("distributor_price", this.product_form.get('distributor_price').value);
    formData.append("weight", this.product_form.get('weight').value);
    formData.append("max_purchase", this.product_form.get('max_purchase').value);
    formData.append("is_new", this.product_form.get('is_new').value);
    formData.append("image", this.product_form.get('image').value);
    formData.append("image_thumbnail", this.product_form.get('image_thumbnail').value);
    formData.append("is_active", this.product_form.get('is_active').value);
    formData.append("is_catalog", this.product_form.get('is_catalog').value);
    
    if (this.form_type == 'Agregar') {
      // Add product
      this.product.add_product(formData).subscribe( res => {
        // Add gallery images
        let formData = new FormData();
        formData.append("product", res['id']);
        for( let image of this.product_form.get('gallery').value){
          if (image){
            formData.set("image", image);
            formData.set("image_thumbnail", image);
            this.product.add_product_gallery(formData).subscribe();
          }
        }

        $.unblockUI();
        this.router.navigateByUrl('/panel/products');
        this.toastr.success("Producto creado correctamente.");
      }, error => {
        console.log(error);
        $.unblockUI();
        this.toastr.error("Error!");
      });

    }else if(this.form_type == 'Editar'){
      // Edit product
      this.product.edit_product(this.id,formData).subscribe( res => {
        // Add gallery images
        let formData = new FormData();
        formData.append("product", this.id);
        for( let image of this.product_form.get('gallery').value){
          if (image){
            formData.set("image", image);
            formData.set("image_thumbnail", image);
            this.product.add_product_gallery(formData).subscribe();
          }
        }
        $.unblockUI();
        this.router.navigateByUrl('/panel/products');
        this.toastr.success("Producto modificado correctamente.");
      }, error => {
        console.log(error);
        $.unblockUI();
        this.toastr.error("Error!");
      });
    }
    
  }

}
