import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  apiURL: string = `${environment.apiUrl}/api/v1/products/`;

  constructor(private http: HttpClient) { }

  products_list(){
    return this.http.get(this.apiURL);
  }

  product_detail(id){
    return this.http.get(`${this.apiURL}${id}/`);
  }

  add_product(data){
    return this.http.post(`${this.apiURL}add/`, data);
  }

  edit_product(id, data){
    return this.http.put(`${this.apiURL}${id}/`, data);
  }

  delete_product(id){
    return this.http.delete(`${this.apiURL}${id}/`);
  }

  product_gallery_list(product_id){
    return this.http.get(`${this.apiURL}gallery/list/${product_id}/`);
  }

  add_product_gallery(data){
    return this.http.post(`${this.apiURL}gallery/add/`, data);
  }

  delete_product_gallery(product_gallery_id){
    return this.http.delete(`${this.apiURL}gallery/${product_gallery_id}/`);
  }

  products_promotion_list(){
    return this.http.get(`${this.apiURL}promotion/`);
  }

  catalogs_list_english(){
    return this.http.get(`${this.apiURL}catalog/file/list/english/`);
  }
  catalogs_list_spanish(){
    return this.http.get(`${this.apiURL}catalog/file/list/spanish/`);
  }
  
  add_catalog(data){
    return this.http.post(`${this.apiURL}catalog/file/create/`, data);
  }

  delete_catalog(id){
    return this.http.delete(`${this.apiURL}catalog/file/${id}/`);
  }

}
