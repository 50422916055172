import { ShippingMethodsService } from './../../../panel/services/shipping-methods.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from './../../../auth/services/auth.service';
import { PersistenceService, StorageType } from 'angular-persistence';
import { EcommerceService } from './../../services/ecommerce.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Select2OptionData } from 'ng-select2';
import {
    FormGroup,
    FormControl,
    Validators,
  } from '@angular/forms';


declare var $: any;
declare var OpenPay: any;

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

    checkout_form = new FormGroup({
        card_type_form: new FormControl('',[Validators.required]),
      });

    dictError = {
        1001: "Los datos que has proporcionados estan incompletos o son erróneos.",
        2004: "El digito identificador del número de tarjeta es invalido.",
        2005: "La fecha de expiración de la tarjeta es anterior a la fecha actual.",
        2006: "EL código de seguridad de la tarjeta no fue proporcionado.",
        2009: "El código de seguridad es invalido.",
        2011: "Tipo de tarjeta no soportada.",
        3001: "La tarjeta fue rechazada.",
        3002: "La tarjeta ha expirado.",
        3003: "La tarjeta no tiene fondos suficientes."
    }


    @ViewChild('sweetAlertAddress', {static: false}) private sweetAlertAddress: SwalComponent;
    @ViewChild('sweetAlertRadio', {static: false}) private sweetAlertRadio: SwalComponent;
    @ViewChild('sweetAlertCard', {static: false}) private sweetAlertCard: SwalComponent;
    @ViewChild('sweetAlertPurchaseAccepted', {static: false}) private sweetAlertPurchaseAccepted: SwalComponent;
    @ViewChild('sweetAlertPurchaseDenied', {static: false}) private sweetAlertPurchaseDenied: SwalComponent;

    cart: any;
    totalCart: any;
    subtotal: string;
    iva: string;
    checkoutResponse: any;
    cartDetail: any;
    shippingMethods: any;
    shippingMethodSelected: any;
    profile: any = {};
    openPay: any;
    idPurchase: number;
    openPayMessage: string;
    shippingMethodSelectedWeights: any;
    shippingCost: number = 0;
    isDistributor: boolean;
    shippingAddressSelected: any;
    invoice: boolean = false;
    card_type: Array<Select2OptionData> = [];
    cardTypeOptions: any;
    constructor(private ecommerceService: EcommerceService, private persistenceService: PersistenceService, private authService: AuthService, private router: Router, private toastr: ToastrService, private shippingMethodService: ShippingMethodsService) { }

    ngOnInit() {
        if(localStorage.getItem('distributor') == 'false' || !localStorage.getItem('distributor')){
            this.isDistributor = false;
        }else{
            this.isDistributor = true;
        }
        this.cart = this.persistenceService.get('cart', StorageType.SESSION);
        this.doCheckout();
        this.getAddress();
        this.getAllProducts();
        this.getShippingMethods();
        this.totalCart = parseFloat(this.persistenceService.get('totalCart', StorageType.SESSION));
        this.subtotal = (this.totalCart/1.16).toFixed(2);
        this.iva = ((this.totalCart/1.16)*0.16).toFixed(2);
        this.totalCart = this.persistenceService.get('totalCart', StorageType.SESSION);
        let card_type: Array<any> = [];
        card_type.push({id:0o4, text:'Tarjeta de crédito'});
        card_type.push({id:28, text:'Tarjeta de débito'});
        this.card_type = card_type;
        this.cardTypeOptions = {
            placeholder: 'Seleccione un tipo de tarjeta...',
            minimumResultsForSearch: Infinity,
            width: '100%',
          };
    }


    ngAfterViewInit(){
        var self = this;
        var cvv, card_number;
        var cartDetail = this.cartDetail;

        $('#cvv').on('change paste keyup', function() { 
            cvv = $(this).val();
        });
        $('#card_number').on('change paste keyup', function() { 
            card_number = $(this).val();
        });
        $(document).ready(function(){
            OpenPay.setId('m5gfzqbcqwpr0f3myzz6');
            OpenPay.setApiKey('pk_7f0e704d26024257a5bde45e03e03f07');
            OpenPay.setSandboxMode(false);
            var deviceSessionId = OpenPay.deviceData.setup("payment-form", "deviceIdHiddenFieldName");

            $('#pay-button').on('click', function(event) {
                if (self.checkout_form.get('card_type_form').value == '') {
                    self.sweetAlertCard.fire();
                    return false;
                }
                if (!$("input[name='shippingAddress']:checked").val()) {
                    self.sweetAlertAddress.fire();
                    return false;
                }
                if (!$("input[name='shippingMethod']:checked").val()) {
                    self.sweetAlertRadio.fire();
                    return false;
                }
                $("#pay-button").prop( "disabled", true);
                OpenPay.token.extractFormAndCreate('payment-form', (success) => {
                    var token_id = success.data.id;
                    $('#token_id').val(token_id);
                    this.openPay = {
                        "shipping_method": self.shippingMethodSelected.id,
                        "description": "Compra en Fleximatic",
                        "shipping_cost": self.shippingCost,
                        "amount": parseFloat(self.subtotal),
                        "tax": parseFloat(self.iva),
                        "total": parseFloat(self.totalCart),
                        "shipping_address": self.shippingAddressSelected.id,
                        "postal_code": self.shippingAddressSelected.postal_code,
                        "state": self.shippingAddressSelected.state,
                        "city": self.shippingAddressSelected.city,
                        "street_number": self.shippingAddressSelected.street_number,
                        "colony": self.shippingAddressSelected.colony,
                        "require_invoice": self.invoice,
                        "sale_products": [],
                        "payment": {
                            "device_id": deviceSessionId,
                            "holder_name": success.data.card.holder_name,
                            "card_number": card_number,
                            "card_type": self.checkout_form.get('card_type_form').value,
                            "expiration_month": success.data.card.expiration_month,
                            "expiration_year": success.data.card.expiration_year,
                            "cvv2": cvv,
                        }
                    }
                    for(let item of cartDetail){
                        this.openPay['sale_products'].push(
                            {
                                "product": item.id, 
                                "quantity": item.qty,
                                "unit_price": parseFloat(item.price),
                                "amount": parseFloat(item.sub),
                                "tax": parseFloat(item.iva),
                                "total": parseFloat(item.total),
                            }
                        );
                    }
                    self.checkout(this.openPay);
                }, (error) => {
                    self.openPayMessage = self.dictError[error.data.error_code];
                    console.log(error.data.error_code);
                    self.sweetAlertPurchaseDenied.fire();
                    $("#pay-button").prop("disabled", false);
                });
            });
        });
    }

    checkout(data: any){
        this.ecommerceService.checkoutOpenPay(data).subscribe(res => {
            this.sweetAlertPurchaseAccepted.fire();
            this.idPurchase = res['id'];
        }, error =>{
            this.openPayMessage = this.dictError[error.error.error_code];
            this.sweetAlertPurchaseDenied.fire();
        });
    }

    doCheckout(){
        this.ecommerceService.checkoutProducts(this.cart).subscribe(res => {
            this.checkoutResponse = res;
            var statusResponse = this.checkoutResponse.find(o => o.status === false);
            if(statusResponse != undefined){
                this.router.navigateByUrl('/ecommerce/cart');
                this.toastr.warning("Algún producto no tiene el stock suficiente");
            }
        });
    }

    getAddress(){
        this.authService.client_profile().subscribe(res => {
            this.profile = res;
            if(this.profile.postal_code === null || this.profile.state === null || this.profile.city === null || this.profile.street_number === null || this.profile.colony === null || this.profile.postal_code === '' || this.profile.state === '' || this.profile.city === '' || this.profile.street_number === '' || this.profile.colony === ''){
                this.router.navigateByUrl('/profile');
                this.toastr.warning("Completa los datos de dirección de facturación.");
            }
            if(this.profile.rfc === null || this.profile.rfc === ''){
                this.router.navigateByUrl('/profile');
                this.toastr.warning("Completa los datos fiscales para continuar con la compra.");
            }
            if(this.profile.shipping_addresses.length < 1){
                this.router.navigateByUrl('/profile');
                this.toastr.warning("Agrega una dirección de envío para continuar con la compra.");
            }
        });
    }

    getAllProducts(){
        this.cartDetail = [];
        for(let item of this.cart['products_list']){
            this.ecommerceService.getProductDetail(item['id']).subscribe(res => {
                if(res['product']['discount'].length == 0){
                    if(this.isDistributor){
                        this.cartDetail.push({
                            "id": res['product'].id,
                            "name": res['product'].name,
                            "qty": item['quantity'],
                            "price": res['product'].distributor_price,
                            "sub": ((res['product'].distributor_price * item['quantity'])/1.16).toFixed(2),
                            "iva": (((res['product'].distributor_price * item['quantity'])/1.16)*0.16).toFixed(2),
                            "weight": res['product'].weight,
                            "total": (res['product'].distributor_price * item['quantity']).toFixed(2),
                        });
                    }else{
                        this.cartDetail.push({
                            "id": res['product'].id,
                            "name": res['product'].name,
                            "qty": item['quantity'],
                            "price": res['product'].customer_price,
                            "sub": ((res['product'].customer_price * item['quantity'])/1.16).toFixed(2),
                            "iva": (((res['product'].customer_price * item['quantity'])/1.16)*0.16).toFixed(2),
                            "weight": res['product'].weight,
                            "total": (res['product'].customer_price * item['quantity']).toFixed(2),
                        });
                    }
                }else{
                    if(this.isDistributor){
                        this.cartDetail.push({
                            "id": res['product'].id,
                            "name": res['product'].name,
                            "qty": item['quantity'],
                            "price": (res['product'].distributor_price - (res['product'].distributor_price * (res['product']['discount'][0]['discount_rate']/100 ))),
                            "sub": (((res['product'].distributor_price - (res['product'].distributor_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity'])/1.16).toFixed(2),
                            "iva": ((((res['product'].distributor_price - (res['product'].distributor_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity'])/1.16)*0.16).toFixed(2),
                            "weight": res['product'].weight,
                            "total": ((res['product'].distributor_price - (res['product'].distributor_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity']).toFixed(2),
                        });
                    }else{
                        this.cartDetail.push({
                            "id": res['product'].id,
                            "name": res['product'].name,
                            "qty": item['quantity'],
                            "price": (res['product'].customer_price - (res['product'].customer_price * (res['product']['discount'][0]['discount_rate']/100 ))),
                            "sub": (((res['product'].customer_price - (res['product'].customer_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity'])/1.16).toFixed(2),
                            "iva": ((((res['product'].customer_price - (res['product'].customer_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity'])/1.16)*0.16).toFixed(2),
                            "weight": res['product'].weight,
                            "total": ((res['product'].customer_price - (res['product'].customer_price * (res['product']['discount'][0]['discount_rate']/100 ))) * item['quantity']).toFixed(2),
                        });
                    }
                }
            });
        }
    }

    getShippingMethods(){
        this.shippingMethodService.shipping_methods_clients_list().subscribe(res => {
            this.shippingMethods = res;
        });
    }

    radioSelected(val: number){
        this.shippingMethodSelected = this.shippingMethods.find(o => o.id == val);
        this.getShippingMethodDetail(this.shippingMethodSelected['id']);
        
    }

    addressSelected(val: number){
        this.shippingAddressSelected = this.profile.shipping_addresses.find(o => o.id == val);
    }

    purchaseIsOk(){
        this.ecommerceService.updateTotalCart(0);
        this.persistenceService.set('cart', {"products_list": []}, {type: StorageType.SESSION});
        this.router.navigateByUrl(`/purchase/${this.idPurchase}`);
    }

    getShippingMethodDetail(id: number){
        this.shippingMethodService.shipping_method_clients_detail(id).subscribe(res => {
            this.shippingMethodSelectedWeights = res['price_by_weight_shipping'];
            this.calculatePackaging();
        });
    }

    compareProducts(a, b) {
        if(a.weight > b.weight) return -1;
        else if(a.weight < b.weight) return 1;
        return 0;
    }
    
    compareMethods(a, b) {
        if(a.weight > b.weight) return 1;
        else if(a.weight < b.weight) return -1;
        return 0;
    }

    calculatePackaging(){
        var totalWeight = 0;
        var allProductsList = [];
        var boxes = [];
        for(let item of this.cartDetail){
            totalWeight += +item.weight * item.qty;
            for(let i=0; i<item.qty; i++){
                allProductsList.push({"id": item.id, "weight": +item.weight});
            }
        }
        for(let item of this.shippingMethodSelectedWeights){
            if(totalWeight <= item.weight){
                boxes.push({"max_weight": item.weight, "weight": totalWeight});
                this.calculatePachagingPrice(boxes);
                return;
            }
        }
        allProductsList.sort(this.compareProducts);
        this.shippingMethodSelectedWeights.sort(this.compareMethods);
        for(let p of allProductsList){
            if(boxes.length == 0){
                boxes.push({"max_weight": this.shippingMethodSelectedWeights[this.shippingMethodSelectedWeights.length - 1].weight, "weight": p.weight});
            }else{
                let index = -1
                for(let b = 0; b < boxes.length; b++){
                    if(boxes[b].weight+p.weight <= boxes[b].max_weight){
                        index = b;
                        break;
                    }
                }
                if(index == -1){
                    boxes.push({"max_weight": this.shippingMethodSelectedWeights[this.shippingMethodSelectedWeights.length - 1].weight, "weight": p.weight});
                }else{
                    boxes[index].weight += p.weight;
                }
            }
        }
        for(let i of boxes){
            for(let x = this.shippingMethodSelectedWeights.length-1; x >= 0; x--){
                if(i.weight <= this.shippingMethodSelectedWeights[x].weight){
                    i.max_weight = this.shippingMethodSelectedWeights[x].weight;
                }
            }
        }
        this.calculatePachagingPrice(boxes);
    }

    calculatePachagingPrice(boxes){
        this.shippingCost = 0;
        for(let i of boxes){
            for(let j of this.shippingMethodSelectedWeights){
                if(i.max_weight == j.weight){
                    this.shippingCost += +j.price;
                }
            }
        }
    }

}
