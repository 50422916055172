import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,  Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../../auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../environments/environment';

declare var $: any;
@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.css']
})
export class UsersFormComponent implements OnInit {

  user_form = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('',[Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]),
    user_role: new FormControl('', [Validators.required]),
    avatar: new FormControl(''),
    is_active: new FormControl('false', Validators.required)
  });

  form_type: string;
  id: string;
  user_obj: any;
  user_roles: any;
  apiUrl: string = environment.apiUrl;

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit() {
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      },
      
    });

    // Get user roles
    this.auth.user_roles_list().subscribe( res =>{
      this.user_roles = res;
      $.unblockUI();
    });

    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == 'add'){
      this.form_type = 'Agregar';
    }else{
      this.form_type = 'Editar';
      this.auth.user_detail(this.id).subscribe( res =>{
        delete res['id'];
        delete res['user_type'];
        this.user_obj = res;
        this.user_obj.user_role = res['user_role'] == null ? '' : res['user_role']['id'];
        this.user_form.setValue(this.user_obj);
        this.user_form.get('avatar').setValue('');
        $.unblockUI();
      }, error => {
        this.router.navigateByUrl('/panel/users');
        this.toastr.error(error['error'].detail, "Error!");
        $.unblockUI();
      });

    }
  }

  onFileChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.warning("Formato inválido.");
      return;
    }

    reader.onload = () => {
      this.user_form.get('avatar').setValue(reader.result as string);
    }
    reader.readAsDataURL(file);
  }

  save_user(){
    const formData = new FormData();
    formData.append("first_name", this.user_form.get('first_name').value);
    formData.append("last_name", this.user_form.get('last_name').value);
    formData.append("email", this.user_form.get('email').value);
    formData.append("user_type", "admin");
    formData.append("user_role", this.user_form.get('user_role').value);
    formData.append("phone", this.user_form.get('phone').value);
    formData.append("avatar", this.user_form.get('avatar').value);
    formData.append("is_active", this.user_form.get('is_active').value);

    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    if (this.form_type == 'Agregar') {
      this.auth.add_user(formData).subscribe( res => {
        $.unblockUI();
        this.router.navigateByUrl('/panel/users');
        this.toastr.success("Usuario creado correctamente.");
      }, error => {
        let errors = error['error'];
        for (let e in errors) {
          this.toastr.error(errors[e],"Error!");
        }
        $.unblockUI();
      });

    }else if(this.form_type == 'Editar'){
      this.auth.edit_user(this.id,formData).subscribe( res => {
        $.unblockUI();
        this.router.navigateByUrl('/panel/users');
        this.toastr.success("Usuario modificado correctamente.");
      }, error => {
        let errors = error['error'];
        for (let e in errors) {
          this.toastr.error(errors[e],"Error!");
        }
        $.unblockUI();
      });
    }
    
  }

}
