import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ProductsService } from '../../services/products.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
    selector: 'app-products-catalog-form',
    templateUrl: './products-catalog-form.component.html',
    styleUrls: ['./products-catalog-form.component.css']
})
export class ProductsCatalogFormComponent implements OnInit {

    catalog_form: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        pdf_url: new FormControl('', [Validators.required]),
        status: new FormControl(true),
        is_English: new FormControl('', [Validators.required])
    });

    constructor(private product: ProductsService, private router: Router, private toastr: ToastrService) { }

    ngOnInit() {}

    save_catalog(){
        const formData = new FormData();
        formData.append("name", this.catalog_form.get('name').value);
        formData.append("pdf_url", this.catalog_form.get('pdf_url').value);
        formData.append("status", this.catalog_form.get('status').value);
        formData.append("is_English", this.catalog_form.get('is_English').value);
        this.product.add_catalog(formData).subscribe( res => {
            console.log(res);
            this.router.navigateByUrl('/panel/products/catalog');
            this.toastr.success("Catálogo agregado correctamente.");
        }, () => {
            $.unblockUI();
            this.toastr.error("Error!");
        });
    }

}
