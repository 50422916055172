import { NavbarComponent } from './../../../portal/shared/navbar/navbar.component';
import { environment } from './../../../../environments/environment';
import { EcommerceService } from './../../services/ecommerce.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

declare var $: any;

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})

export class ProductsComponent implements OnInit, OnDestroy {
    
    categoriesList: any;
    title: string;
    apiUrl: string = environment.apiUrl;
    type: string;
    productsList: any;
    pagination: number;
    subscription: any;
    page: number;
    objectCount: number;
    isDistributor: boolean;
    id: any;

    private sub: any;

    constructor(private router: Router, private ecommerceService: EcommerceService, private route: ActivatedRoute) {
        this.subscription = this.ecommerceService.getFilterMessage().subscribe(message => {
            this.title = "Resultados de búsqueda de "+message;
            this.getProductsByFilter();
        });
    }
    
    ngOnInit() {
        if(localStorage.getItem('distributor') == 'false' || !localStorage.getItem('distributor')){
            this.isDistributor = false;
        }else{
            this.isDistributor = true;
        }

        this.getProductsCategories();
       
        this.sub = this.route.params.subscribe( () => {
            this.type = this.route.snapshot.paramMap.get("type");
            this.page = parseInt(this.route.snapshot.paramMap.get("page"));

            if(this.type === 'all'){
                this.getAllProducts(this.page);
            }else if(this.type === 'new'){
                this.getNewProducts(this.page);
            }else if(this.type === 'category'){
                this.id = this.route.snapshot.paramMap.get("id");
                this.getProductsByCategory(this.id, this.page);
            }else{
                this.getProductsByFilterByGet(this.type, this.page);
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.sub.unsubscribe();
    }

    getAllProducts(page){
        this.ecommerceService.getAllProducts().subscribe(res => {
            this.pagination = res['pages'];
            this.objectCount = res['products'];
        });
        this.ecommerceService.getAllProductsPaginated(page).subscribe(res => {
            this.productsList = res;
        });
        this.title = "Todos los productos";
    }

    getNewProducts(page) {
        this.ecommerceService.getNewestProducts().subscribe(res => {
            this.pagination = res['pages'];
            this.objectCount = res['products'];
        });
        this.ecommerceService.getNewestProductsPaginated(page).subscribe(res => {
            this.productsList = res;
        })
        this.title = "Productos nuevos";
    }

    getProductsByCategory(id, page){
        this.ecommerceService.getProductsByCategory(id).subscribe(res => {
            this.pagination = res['pages'];
            this.objectCount = res['products'];
        });
        this.ecommerceService.getProductsByCategoryPaginated(id, page).subscribe(res => {
            this.productsList = res;
            if(this.productsList.length > 0){
                this.title = this.productsList[0].product.category.name;
            }
        });
    }

    getProductsByFilter(){
        this.productsList = this.ecommerceService.productsFilterList;
        this.page = 1;
        this.pagination = this.ecommerceService.pagination;
        this.objectCount = this.ecommerceService.objectCount;
    }

    getProductsByFilterByGet(data, page){
        this.ecommerceService.getProductsByFilter(data).subscribe(res => {
            this.pagination = res['pages'];
            this.objectCount = res['products'];
        });
        this.ecommerceService.getProductsByFilterPaginated(data, page).subscribe(res => {
            this.productsList = res;
            this.title = "Resultados de búsqueda de "+data;
        });
    }
    
    getProductsCategories(){
        this.ecommerceService.getProductsCategories().subscribe(res => {
            this.categoriesList = res;
        });
    }

    selectValue(event){
        if(event.target.value === 'new'){
            this.page = 1;
            this.getNewProducts(1);
            this.router.navigateByUrl("/products/new/"+this.page);
        }else if(event.target.value === 'all'){
            this.page = 1;
            this.getAllProducts(1);
            this.router.navigateByUrl("/products/all/"+this.page);
        }
    }

}