import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,  Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Select2OptionData } from 'ng-select2';

import { InventoriesService } from '../../services/inventories.service';
import { ProductsService } from '../../services/products.service';
import { environment } from '../../../../environments/environment';


declare var $: any;
@Component({
  selector: 'app-inventories-form',
  templateUrl: './inventories-form.component.html',
  styleUrls: ['./inventories-form.component.css']
})
export class InventoriesFormComponent implements OnInit {

  inventory_form: FormGroup = new FormGroup({
    product: new FormControl('', Validators.required),
    category: new FormControl({value: '', disabled: true}),
    brand: new FormControl({value: '', disabled: true}),
    description: new FormControl({value: '', disabled: true}),
    customer_price: new FormControl({value: '', disabled: true}),
    distributor_price: new FormControl({value: '', disabled: true}),
    stock: new FormControl('', [Validators.required, Validators.min(1)]),
  });
  products: Array<Select2OptionData> = [];
  product_image: any;
  options: any;
  apiUrl: string = environment.apiUrl;

  constructor(private inventory: InventoriesService,private product: ProductsService, private router: Router, private toastr: ToastrService) {  }

  ngOnInit() {
    $.blockUI({ 
      message: '<i class="icon-spinner4 spinner"></i>',
      overlayCSS: {
          backgroundColor: '#1b2024',
          opacity: 0.8,
          zIndex: 1200,
          cursor: 'wait'
      },
      css: {
          border: 0,
          color: '#fff',
          padding: 0,
          zIndex: 1201,
          backgroundColor: 'transparent'
      }
    });
    this.inventory.product_list().subscribe( res =>{
      
      // Add products to an array for select2
      let product_list: Array<any> = [];
      for( let i in res){
        product_list.push({id: res[i].id, text: `${res[i].code} - ${res[i].name}` });
      }
      this.products = product_list;
      $.unblockUI();
    });
    this.options = {
      placeholder: "Seleccione un producto...",
      width: "100%"
    }
  }

  get_product_detail(e) {
    if(e){
      $.blockUI({ 
        message: '<i class="icon-spinner4 spinner"></i>',
        overlayCSS: {
            backgroundColor: '#1b2024',
            opacity: 0.8,
            zIndex: 1200,
            cursor: 'wait'
        },
        css: {
            border: 0,
            color: '#fff',
            padding: 0,
            zIndex: 1201,
            backgroundColor: 'transparent'
        }
      });
      this.product.product_detail(e).subscribe(res => {
        this.inventory_form.get('category').setValue(res['category'].name);
        this.inventory_form.get('brand').setValue(res['brand'].name);
        this.inventory_form.get('description').setValue(res['description']);
        this.inventory_form.get('customer_price').setValue(res['customer_price']);
        this.inventory_form.get('distributor_price').setValue(res['distributor_price']);
        this.product_image = res['image'];

        $.unblockUI();
      });
    }
  }

  save_inventory(){
    let inventory_product = {
      ...this.inventory_form.value,
      id: 0
    };
    this.inventory.add_inventory(inventory_product).subscribe( res => {
      this.router.navigateByUrl('/panel/inventories');
      this.toastr.success("Inventario del producto creado correctamente.");
    }, error => {
      console.log(error);
      this.toastr.error("Error!");
    });
  }

}
